import {
  Button,
  FormControl,
  Grid,
  Input,
  InputLabel,
  Paper,
  Typography,
  FormHelperText,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import TranslateIcon from "@material-ui/icons/Translate";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { AuthContext } from "../../App";
import auth from "../../utils/auth";
import { redirect } from "../../utils/pagePermission";
import { updateUser } from "../../api/user";
import CustomSnackBar from "../OperatorConfig/SnackBar";

const Login = (props) => {
  const { t, i18n } = useTranslation();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { state, dispatch } = useContext(AuthContext);
  const [inputError, setInputError] = useState(false);
  const [wrongPWError, setWrongPWError] = useState(false);
  const [helperText, setHelperText] = useState("");
  // Default section when login
  const section = "manage";
  const [showPassword, setShowPassword] = useState(false);
  const [showTempPassword, setShowTempPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);

  const [resetPw, setResetPw] = useState(false);
  const [tempPassword, setTempPassword] = useState("");
  const [tempRePassword, setTempRePassword] = useState("");
  const [pwError, setPwError] = useState(false);
  // const [oldPwError, setOldPwError] = useState(false);

  const [msg, setMsg] = useState({ show: false, type: "success", msg: "" });

  useEffect(() => {
    if (localStorage.getItem("version") === "1.0") {
      if (state.userId && state.permissions && state.routePermissionId) {
        redirect(section, props.history, "/", "court-root", state.permissions);
      } else if (
        localStorage.getItem("userId") &&
        localStorage.getItem("permissions") &&
        localStorage.getItem("routePermissionId")
      ) {
        dispatch({
          type: "LOGIN",
          payload: {
            id: localStorage.getItem("userId"),
            username: localStorage.getItem("username"),
            operatorId: localStorage.getItem("operatorId"),
            permissionPage: localStorage.getItem("permissions"),
            routePermissionId: localStorage.getItem("routePermissionId"),
            pwExpiry: localStorage.getItem("pwExpiry"),
            ...(localStorage.getItem("isTd")
              ? { isTd: localStorage.getItem("isTd") }
              : null),
            ...(localStorage.getItem("isMegaOp")
              ? { isMegaOp: localStorage.getItem("isMegaOp") }
              : null),
          },
        });
        redirect(section, props.history, "/", "court-root", state.permissions);
      }
    } else {
      localStorage.clear();
      localStorage.setItem("version", "1.0");
    }
  }, []);

  const clearTemp = () => {
    setResetPw(false);
    setWrongPWError(false);
    setPassword("");
    setTempPassword("");
    setTempRePassword("");
    setHelperText("");
  };

  const login = async () => {
    if (username && password) {
      const result = await auth.authorized(username, password);
      // console.log(result);
      if (result && result?.code === 200) {
        // console.log("==== result", result);
        if (moment(moment(result.pwExpiry)).diff(Date.now()) < 0) {
          console.log("pw expired");
          setWrongPWError(true);
          setResetPw(true);
          setHelperText(t("密碼過期，請重設密碼"));

          if (
            tempPassword &&
            tempRePassword &&
            tempPassword === tempRePassword
          ) {
            const response = await updateUser({
              ...result,
              password: tempPassword,
            });
            if (response && response.newPwExpiry) {
              setMsg({
                show: true,
                type: "success",
                msg: t("成功更新密碼,請重新登入"),
              });
              clearTemp();
            }
          }
        } else {
          dispatch({
            type: "LOGIN",
            payload: {
              id: result.id,
              username: result.username,
              operatorId: result.operator?.id,
              ...(result.isTd ? { isTd: result.isTd } : null),
              ...(result.isMegaOp ? { isMegaOp: result.isMegaOp } : null),
              permissionPage: result.permissionPage,
              routePermissionId: result.routePermissionId,
              pwExpiry: result.pwExpiry,
            },
          });
          redirect(
            section,
            props.history,
            "/",
            "court-root",
            state.permissions
          );
        }
      } else {
        setWrongPWError(true);

        if (result.code === 401) {
          console.log("wrong password");
          setHelperText(t("用戶名稱或密碼錯誤"));
        }
      }
    } else {
      console.log("no input");
      setInputError(true);
      setHelperText(t("請輸入用戶名稱及密碼"));
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      login();
    }
  };

  const handleLanguageChange = () => {
    if (i18n.language === "zh") {
      i18n.changeLanguage("en");
      localStorage.setItem("lang", "en");
    } else {
      i18n.changeLanguage("zh");
      localStorage.setItem("lang", "zh");
    }
  };

  const handleClickShowPassword = (pwType) => {
    switch (pwType) {
      case "pw":
        setShowPassword(!showPassword);
        break;
      case "tempPw":
        setShowTempPassword(!showTempPassword);
        break;
      case "rePw":
        setShowRePassword(!showRePassword);
        break;
      default:
        break;
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const allStorage = () => {
    const values = [];
    const keys = Object.keys(localStorage);
    let i = keys.length;

    while (i--) {
      values.push(`${keys[i]}:${localStorage.getItem(keys[i])}`);
    }

    return values;
  };

  return (
    <div
      style={{ width: "100vw", height: "100vh", display: "flex" }}
      onKeyDown={handleKeyDown}
    >
      <div style={{ width: "400px", margin: "auto" }}>
        <Paper
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 24,
            flexDirection: "column",
          }}
        >
          <Typography variant="inherit" style={{ textAlign: "center" }}>
            {t("網上管理平台")}
          </Typography>
          <Grid container>
            <Grid item xs={12} style={{ marginBottom: "10px" }}>
              <FormControl
                error={(inputError && !username) || wrongPWError}
                style={{ width: "100%" }}
              >
                <InputLabel>{t("用戶名稱")}</InputLabel>
                <Input
                  value={username}
                  onChange={(event) => {
                    setUsername(event.target.value);
                    setWrongPWError(false);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: "10px" }}>
              <FormControl
                error={(inputError && !password) || wrongPWError}
                style={{ width: "100%" }}
              >
                <InputLabel>{resetPw ? t("輸入舊密碼") : t("密碼")}</InputLabel>
                <Input
                  value={password}
                  onChange={(event) => {
                    setPassword(event.target.value);
                    setWrongPWError(false);
                  }}
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          handleClickShowPassword("pw");
                        }}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <FormHelperText
                error={(inputError && (!username || !password)) || wrongPWError}
              >
                {helperText}
              </FormHelperText>
            </Grid>

            {resetPw && (
              <Grid item xs={12} style={{ marginBottom: "10px" }}>
                <FormControl
                  // error={(inputError && !password) || wrongPWError}
                  style={{ width: "100%" }}
                >
                  <InputLabel>{t("輸入新密碼")}</InputLabel>
                  <Input
                    value={tempPassword}
                    onChange={(event) => {
                      setPwError(
                        tempRePassword
                          ? event.target.value !== tempRePassword
                          : false
                      );
                      setTempPassword(event.target.value);
                    }}
                    type={showTempPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => {
                            handleClickShowPassword("tempPw");
                          }}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showTempPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                {/* <FormHelperText
                  
                >
                  {helperText}
                </FormHelperText> */}
              </Grid>
            )}
            {resetPw && tempPassword && (
              <Grid item xs={12} style={{ marginBottom: "10px" }}>
                <FormControl
                  error={pwError || !tempRePassword}
                  style={{ width: "100%" }}
                >
                  <InputLabel>{t("再次輸入新密碼")}</InputLabel>
                  <Input
                    value={tempRePassword}
                    onChange={(event) => {
                      setPwError(event.target.value !== tempPassword);
                      setTempRePassword(event.target.value);
                    }}
                    type={showRePassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => {
                            handleClickShowPassword("rePw");
                          }}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <FormHelperText
                //
                >
                  {pwError && t("密碼不相同")}
                </FormHelperText>
              </Grid>
            )}

            <Grid container justifyContent="space-between">
              <Grid item xs>
                <Button
                  disabled={
                    resetPw && (!tempPassword || !tempRePassword || pwError)
                  }
                  variant="contained"
                  color="primary"
                  style={{ marginBottom: "10px" }}
                  onClick={login}
                >
                  {resetPw ? t("更改密碼") : t("登入")}
                </Button>
              </Grid>
              <Grid item xs style={{ textAlign: "right" }}>
                <Button onClick={handleLanguageChange}>
                  <TranslateIcon style={{ color: "#3f51b5" }} />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </div>
      <CustomSnackBar msg={msg} setMsg={setMsg} />
    </div>
  );
};

export default withRouter(Login);
