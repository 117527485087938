import React from "react";
import NoResultSVG from "../../assets/no-results.svg";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Paper } from "@material-ui/core";
const Forbidden = () => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        flexDirection: "column",
        backgroundColor: "lightgrey",
      }}
    >
      <Paper
        elevation={3}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          padding: 20,
        }}
      >
        <img src={NoResultSVG} width={200} />
        <div style={{ marginBottom: 10, fontSize: 20 }}>{t("notFound")}</div>
        <div>
          <Button
            variant="contained"
            color="primary"
            style={{ margin: 5 }}
            onClick={() => {
              history.goBack();
            }}
          >
            {t("prevPage")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ margin: 5 }}
            onClick={() => {
              window.location.href = "../";
              localStorage.clear();
            }}
          >
            {t("logout")}
          </Button>
        </div>
      </Paper>
    </div>
  );
};

export default Forbidden;
