import { constructParams } from "../utils/data";
import Constants from "../utils/constants";

const { API_LINK } = Constants;

// const OMP_360_API_URL = "http://localhost:7074/api";
const OMP_360_API_URL = "/api-new";

export const fetchGMBConfiguration = async (filter) => {
  const result = await (
    await fetch(`/api/gmb-configuration${constructParams(filter)}`)
  ).json();
  return result?.data;
};

export const findGMBConfiguration = async (filters) => {
  const result = await (
    await fetch(`/api/gmb-configuration${constructParams(filters)}`)
  ).json();
  return result?.data;
};

export const fetchSettings = async (userId) => {
  const result = await (
    await fetch(`/api/settings${userId ? `?userId=${userId}` : ""}`)
  ).json();
  return result?.data;
};

export const fetchEtaAppSettings = async (operatorId) => {
  const result = await (
    await fetch(
      `${OMP_360_API_URL}/eta-app-setting${operatorId ? `/${operatorId}` : ``}`
    )
  ).json();
  return result?.data;
};

export const addEtaAppSetting = async (payload) => {
  const result = await (
    await fetch(`${OMP_360_API_URL}/eta-app-setting`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
  ).json();
  return result?.data;
};

export const updateEtaAppSetting = async (payload) => {
  const result = await (
    await fetch(`${OMP_360_API_URL}/eta-app-setting`, {
      method: "PUT",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
  ).json();
  return result?.data;
};

export const deleteEtaAppSetting = async (payload) => {
  const result = await (
    await fetch(`${OMP_360_API_URL}/eta-app-setting`, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
  ).json();
  return result?.data;
};

export const fetchPresetAnnouncement = async (operatorId) => {
  console.log(operatorId);
  const result = await (
    await fetch(
      `${OMP_360_API_URL}/preset-announcement${
        operatorId ? `/${operatorId}` : ``
      }`
    )
  ).json();
  return result?.data;
};

export const updatePresetAnnouncement = async (payload) => {
  const result = await (
    await fetch(`${OMP_360_API_URL}/preset-announcement`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
  ).json();
  return result?.data;
};

export const deletePresetAnnouncement = async (payload) => {
  const result = await (
    await fetch(`${OMP_360_API_URL}/preset-announcement`, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
  ).json();
  return result?.data;
};

export const fetchOmpConfig = async () => {
  const result = await (await fetch(`${OMP_360_API_URL}/omp-config`)).json();
  return result?.data;
}