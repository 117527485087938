import React, { useMemo, useState } from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  Button,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import CloseIcon from "@material-ui/icons/Close";
import auth from "../../utils/auth";
import { updateUser } from "../../api/user";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  margin: {
    margin: theme.spacing(1),
  },
  blue: {
    background: "#3399ff",
    "&:hover": { background: "#3399ff" },
  },
  green: {
    background: "#008212",
    "&:hover": { background: "#008212" },
  },
  grey: {
    background: "#828282",
    "&:hover": { background: "#828282" },
  },
}));

const AccountModal = ({ open, setOpen, username, setMsg }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [tempOldPassword, setTempOldPassword] = useState("");
  const [tempPassword, setTempPassword] = useState("");
  const [tempRePassword, setTempRePassword] = useState("");

  const [validate, setValidate] = useState(false);

  const [pwError, setPwError] = useState(false);
  const [oldPwError, setOldPwError] = useState(false);

  const [pwExpiry, setPwExpiry] = useState("");

  useMemo(() => {
    const pwExpiryDate = localStorage.getItem("pwExpiry");
    if (pwExpiryDate) {
      setPwExpiry(localStorage.getItem("pwExpiry"));
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const clearTemp = () => {
    setTempOldPassword("");
    setTempPassword("");
    setTempRePassword("");
    setOldPwError(false);
  };

  const handleUpdateUser = async () => {
    // check old password if return ok then run update password api
    if (tempOldPassword && tempPassword && tempRePassword && !pwError) {
      const result = await auth.authorized(username, tempOldPassword);
      // console.log(result);

      if (result && !(result?.code >= 400)) {
        const response = await updateUser({
          ...result,
          password: tempPassword,
        });

        // console.log(response);
        if (response) {
          if (response.newPwExpiry) {
            localStorage.setItem(
              "pwExpiry",
              moment(response.newPwExpiry).format("YYYY-MM-DD HH:mm:ss")
            );
            setPwExpiry(
              moment(response.newPwExpiry).format("YYYY-MM-DD HH:mm:ss")
            );
          }
          setMsg({ show: true, type: "success", msg: t("成功更新密碼") });
          clearTemp();
          handleClose();
        }
      } else {
        setOldPwError(true);
      }
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle onClose={handleClose}>
        <Grid item xs={10}>
          {`${t("帳戶資料")}`}
        </Grid>
        <Grid item xs={1}>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <div style={{ width: "500px", height: "500px", background: "white" }}>
          <Grid container>
            <Grid item xs={3} className={classes.flexCenter}>
              {t("用戶名稱")}
            </Grid>
            <Grid item xs={8} className={classes.flexCenter}>
              <FormControl
                style={{ width: "100%" }}
                // error={validate && !tempUsername}
              >
                <InputLabel htmlFor="username" />
                <Input
                  disabled
                  id="my-input"
                  name="username"
                  aria-describedby="my-helper-text"
                  value={username}
                  onChange={(event) => {
                    // setIsUsernameChanged(true);
                    // setTempUsername(event.target.value);
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          {/* {pwExpiry && (
            <Grid container>
              <Grid item xs={12} className={classes.flexCenter}>
                {`${t("密碼到期日")} ${pwExpiry}`}
              </Grid>
            </Grid>
          )} */}
          <Grid container>
            <Grid
              item
              xs={3}
              className={classes.flexCenter}
              style={{ paddingTop: "2em", fontWeight: "bold" }}
            >
              {t("更改密碼")}
            </Grid>
            <Grid
              item
              xs={8}
              className={classes.flexCenter}
              style={{ paddingTop: "2em" }}
            >
              {pwExpiry && `(${t("密碼到期日")} ${pwExpiry})`}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={3} className={classes.flexCenter}>
              {t("輸入舊密碼")}
            </Grid>
            <Grid item xs={8} className={classes.flexCenter}>
              <FormControl
                style={{ width: "100%" }}
                error={tempPassword && oldPwError}
              >
                <InputLabel htmlFor="password" />
                <Input
                  id="my-input"
                  name="password"
                  aria-describedby="my-helper-text"
                  type="password"
                  value={tempOldPassword}
                  onChange={(event) => {
                    setTempOldPassword(event.target.value);
                  }}
                />
                {/* {(isAddUser || isUsernameChanged) && ( */}
                <FormHelperText id="component-error-text">
                  {oldPwError ? t("舊密碼錯誤") : t("必填")}
                </FormHelperText>
                {/* )} */}
              </FormControl>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={3} className={classes.flexCenter}>
              {t("輸入新密碼")}
            </Grid>
            <Grid item xs={8} className={classes.flexCenter}>
              <FormControl
                style={{ width: "100%" }}
                // error={
                //   validate &&
                //   ((isAddUser && !tempPassword) ||
                //     (isUsernameChanged && !tempPassword))
                // }
              >
                <InputLabel htmlFor="password" />
                <Input
                  id="my-input"
                  name="password"
                  aria-describedby="my-helper-text"
                  type="password"
                  value={tempPassword}
                  onChange={(event) => {
                    setPwError(
                      tempRePassword
                        ? event.target.value !== tempRePassword
                        : false
                    );
                    setTempPassword(event.target.value);
                  }}
                />
                {/* {(isAddUser || isUsernameChanged) && ( */}
                <FormHelperText id="component-error-text">
                  {t("必填")}
                </FormHelperText>
                {/* )} */}
              </FormControl>
            </Grid>
          </Grid>
          {tempPassword && (
            <Grid container>
              <Grid item xs={3} className={classes.flexCenter}>
                {t("再次輸入新密碼")}
              </Grid>
              <Grid item xs={8} className={classes.flexCenter}>
                <FormControl
                  style={{ width: "100%" }}
                  error={pwError || (validate && !tempRePassword)}
                >
                  <InputLabel htmlFor="rePassword" />
                  <Input
                    id="my-input"
                    aria-describedby="my-helper-text"
                    type="password"
                    value={tempRePassword}
                    onChange={(event) => {
                      setPwError(event.target.value !== tempPassword);
                      setTempRePassword(event.target.value);
                    }}
                  />
                  <FormHelperText id="component-error-text">
                    {pwError ? t("密碼不相同") : t("必填")}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          )}

          <Button
            disabled={
              !tempOldPassword || !tempPassword || !tempRePassword || pwError
            }
            variant="contained"
            color="primary"
            className={[classes.margin, classes.blue]}
            onClick={handleUpdateUser}
          >
            {t("更改密碼")}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={[classes.margin, classes.grey]}
            onClick={() => clearTemp()}
          >
            {t("清除")}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default AccountModal;
