import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { createBrowserHistory } from "history";
import React, { lazy, Suspense, useEffect, useReducer, useState } from "react";
import { CookiesProvider } from "react-cookie";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import "./App.scss";
import LoadingPage from "./components/LoadingPage";
import ProtectedRoute from "./components/ProtectedRoute";
import CourtRoot from "./containers/CourtRoot";
import Forbidden from "./containers/Forbidden";
import Login from "./containers/Login";
import {
  determineIsTd,
  determineIsMegaOp,
  getPermissionCode,
} from "./utils/pagePermission";
import { ReactQueryDevtools } from "react-query/devtools";
import { fetchOmpConfig } from "./api/config";

import "./utils/i18n";
import { QueryClient, QueryClientProvider } from "react-query";
import i18n from "./utils/i18n";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

export const AuthContext = React.createContext();

const queryClient = new QueryClient();

const history = createBrowserHistory();

history.listen((_) => {
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
});

const initState = {
  userId: null,
  permissions: [],
  // permissionGroup: null,
  routePermissionId: null,
  isTd: false,
  isMegaOp: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      localStorage.setItem("userId", action.payload.id);
      localStorage.setItem("username", action.payload.username);
      localStorage.setItem("operatorId", action.payload.operatorId);
      localStorage.setItem("permissions", action.payload.permissionPage);
      localStorage.setItem("pwExpiry", action.payload.pwExpiry);
      if (action.payload.routePermissionId) {
        localStorage.setItem(
          "routePermissionId",
          action.payload.routePermissionId
        );
      }
      if (action.payload.isTd) {
        localStorage.setItem("isTd", action.payload.isTd);
      }
      if (action.payload.isMegaOp) {
        localStorage.setItem("isMegaOp", action.payload.isMegaOp);
      }
      localStorage.setItem("version", "1.0");
      return {
        ...state,
        userId: action.payload.id,
        operatorId: action.payload.operatorId,
        permissions: action.payload.permissionPage,
        routePermissionId: action.payload.routePermissionId,
        pwExpiry: action.payload.pwExpiry,
      };
    case "LOGOUT":
      localStorage.removeItem("userId");
      localStorage.removeItem("operatorId");
      localStorage.removeItem("permissions");
      localStorage.removeItem("isTd");
      localStorage.removeItem("isMegaOp");
      localStorage.removeItem("routePermissionId");
      localStorage.removeItem("pwExpiry");
      return {
        ...state,
        userId: null,
        operatorId: null,
        permissions: [],
        isTd: false,
        isMegaOp: false,
        routePermissionId: null,
        pwExpiry: null,
      };
    default:
      return state;
  }
};

function App() {
  const [state, dispatch] = useReducer(reducer, initState);

  useEffect(() => {
    if (!localStorage.getItem("version")) {
      dispatch({ type: "LOGOUT" });
    }
    fetchOmpConfig().then(result => setConfig(result || null));

  }, []);

  const [config, setConfig] = useState(undefined);

  useEffect(() => {
    if (config?.defaultLanguage && !localStorage.getItem("lang")) {
      i18n.changeLanguage(config.defaultLanguage);
      localStorage.setItem("lang", config.defaultLanguage);
    }
  }, [config]);

  return (
    <QueryClientProvider client={queryClient}>
      <AuthContext.Provider value={{ state, dispatch }}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <CookiesProvider>
              <Router history={history}>
                <Suspense fallback={<LoadingPage />}>
                  <Switch>
                    {/* <Route path="/" exact component={Root} />
                <Route path="/add" exact component={Root} /> */}
                    {/* <Route
                      path="/"
                      exact
                      render={(props) => <Redirect to="/login" />}
                    /> */}
                    <Route path="/login" exact component={Login} config={config} />
                    <Route path="/forbidden" exact component={Forbidden} config={config} />
                    <Route
                      path="/"
                      exact
                      config={config}
                      component={() => {
                        // Auto redirect when in root path
                        window.location.href = "./#/manage";
                        return null;
                      }}
                    />
                    <ProtectedRoute
                      pagePermission={getPermissionCode("manage", "court-root")}
                      path="/:section"
                      component={CourtRoot}
                      config={config}
                    />
                    {/* <ProtectedRoute path="/:userId/:courtId" component={CourtRoot} /> */}
                    {/* <Route path="/:courtId" component={CourtRoot} /> */}
                  </Switch>
                </Suspense>
              </Router>
            </CookiesProvider>
          </MuiPickersUtilsProvider>
        </LocalizationProvider>
      </AuthContext.Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
