import React, { lazy, Suspense, useContext, useState, useEffect } from "react";
import { Switch, withRouter, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import LoadingPage from "../../components/LoadingPage";
import MenuBarLayout from "../../components/MenuBarLayout";
import ProtectedRoute from "../../components/ProtectedRoute";
import Forbidden from "../Forbidden";

import { getPermissionCode } from "../../utils/pagePermission";
import routes, {
  getSectionItemByName,
  routePermissions,
} from "../../utils/route";
import { AuthContext } from "../../App";

import { fetchOmpConfig } from "../../api/config";

const CourtRoot = ({ section, config }) => {
  const history = useHistory();
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",

      // backgroundColor: '#F2F2F2'
    },
    content: {
      flexGrow: 1,
      // padding: theme.spacing(3),
      // margin: '64px 0 24px 0'
    },
  }));
  // const { section } = props.match.params;
  const classes = useStyles();
  const [flatSiteMap, setFlatSiteMap] = useState([]);

  useEffect(() => {
    try {
      setFlatSiteMap(routePermissions(section));
    } catch (err) {
      history.replace("/forbidden");
    }
  }, [section]);

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <MenuBarLayout config={config}>
          <div style={{ width: "100%", height: "100%" }}>
            {/* <ProtectedRoute path="/" exact component={Root} /> */}

            <Suspense fallback={<LoadingPage />}>
              <Switch>
                {flatSiteMap?.length > 0 &&
                  flatSiteMap.map(({ name, link, path, component }) => {
                    try {
                      if (!component) return null;
                      return (
                        <ProtectedRoute
                          key={name}
                          pagePermission={getPermissionCode(section, name)}
                          path={[link, path]
                            .filter((obj) => obj !== undefined)
                            .map(
                              (url) =>
                                `/${getSectionItemByName(section).name}${url}`
                            )}
                          exact={link === "/" || !!(link && path)}
                          section={section}
                          component={component}
                          config={config}
                        />
                      );
                    } catch (err) {
                      history.replace("/forbidden");
                    }
                  })}
              </Switch>
            </Suspense>
          </div>
        </MenuBarLayout>
      </main>
    </div>
  );
};

export default withRouter(CourtRoot);
