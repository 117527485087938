import Speeding from "../assets/performance.png";
import HighTemp from "../assets/high-temperature.png";
import LowVoltage from "../assets/low-battery.png";
import Crash from "../assets/car-accident.png";
import HighRPM from "../assets/car-engine.png";
import SharpTurn from "../assets/directions.png";
import Alert from "../assets/warning.png";
import Accelerate from "../assets/acceleration.png";
import HandBrake from "../assets/handbrake.png";
import IdleEngine from "../assets/idle_engine.png";
import Towing from "../assets/tow-truck.png";
import Power from "../assets/power.png";
import Emission from "../assets/emission.png";
import Lane from "../assets/lane.png";
import Fatigue from "../assets/fatigue.png";
import PowerOff from "../assets/power_off.png";
import KeyOn from "../assets/key_on.png";
import KeyOff from "../assets/key_off.png";
import Ring from "../assets/ring.png";
import * as L from "leaflet";

const NAV_HEIGHT = 96;
const HEAD_BAR_HEIGHT = 64;
const DESTINATIONS = [
  [22.337423, 114.174402],
  [22.285554, 114.217431],
  [22.297477, 114.169468],
  [22.392536, 113.976525],
  [22.340653, 114.202014],
  [22.316163, 114.161662],
  [22.319876, 114.208458],
  [22.382084, 114.188288],
  [22.323374, 114.258215],
  [22.370796, 114.111306],
  [22.31219, 114.225139],
  [22.304712, 114.188816],
  [22.280377, 114.186015],
  [22.444071, 114.035656],
  [22.315257, 114.171007],
  [22.21808, 114.209772],
  [22.375662, 114.112537],
  [22.318634, 114.168537],
  [22.370844, 114.11133],
  [22.282715, 114.155631],
  [22.298284, 114.167848],
  [22.280857, 114.185289],
  [22.285756, 114.158057],
  [22.33743, 114.174296],
  [22.277829, 114.165122],
  [22.294398, 114.17507],
  [22.343347, 114.125021],
];

const DATA = [
  {
    key: 1,
    license: `RW${Math.floor(1000 + Math.random() * 9000)}`,
    location: { lat: 22.334441, lng: 114.228479 },
    dests: ["Yuen Long", "Tsing Yi", "Tsuen Wan"],
    currDest: 1,
    status: "transit",
  },
  {
    key: 2,
    license: `RW${Math.floor(1000 + Math.random() * 9000)}`,
    location: { lat: 22.369022, lng: 114.122443 },
    dests: ["Yuen Long", "Tsing Yi", "Tsuen Wan"],
    currDest: 0,
    status: "pending",
  },
  {
    key: 3,
    license: `RW${Math.floor(1000 + Math.random() * 9000)}`,
    location: { lat: 22.309946, lng: 114.184231 },
    dests: ["Yuen Long", "Tsing Yi", "Tsuen Wan"],
    currDest: 1,
    status: "delivering",
  },
  {
    key: 4,
    license: `RW${Math.floor(1000 + Math.random() * 9000)}`,
    location: { lat: 22.29205, lng: 114.208184 },
    dests: ["Yuen Long", "Tsing Yi", "Tsuen Wan"],
    currDest: 2,
    status: "delivered",
  },
];

const TRIPS = [
  {
    key: 1,
    date: "8/25/2019",
    startTime: "0730",
    endTime: "1005",
    dests: ["Yuen Long", "Tsing Yi", "Tsuen Wan"],
    timeUsed: "2H34M",
  },
];

const TIME_RANGE_MENU = [
  "Today",
  "Yesterday",
  "This Week",
  "Last Week",
  "This Month",
  "Last Month",
  "This Year",
  "Last Year",
  "Custom Range",
];

// L/km
const FUEL_PER_KM = 1 / 4.2;
// g/km
const CO2_PER_KM = 624;

const DRAWER_WIDTH = 240;

const ALARM_LIST = {
  speeding: {
    title: "Speeding",
    icon: Speeding,
    type: "safety",
    level: "inspect",
  },
  low_voltage: {
    title: "Low Voltage",
    icon: LowVoltage,
    type: "vehicle",
    level: "abnormal",
  },
  high_engine_coolant_temperature: {
    title: "High Engine Coolant Temperature",
    icon: HighTemp,
    type: "vehicle",
    level: "abnormal",
  },
  hard_acceleration: {
    title: "High Acceleration",
    icon: Accelerate,
    type: "safety",
    level: "abnormal",
  },
  hard_deceleration: {
    title: "High Deceleration",
    icon: HandBrake,
    type: "safety",
    level: "abnormal",
  },
  idle_engine: {
    title: "Idle Engine",
    icon: IdleEngine,
    type: "green",
    level: "inspect",
  },
  towing: { title: "Towing", icon: Towing, type: "inspect", level: "inspect" },
  high_rpm: {
    title: "High RPM",
    icon: HighRPM,
    type: "vehicle",
    level: "abnormal",
  },
  power_on: {
    title: "Power On",
    icon: Power,
    type: "inspect",
    level: "inspect",
  },
  exhaust_emission: {
    title: "Exhaust Emission",
    icon: Emission,
    type: "green",
    level: "abnormal",
  },
  quick_lane_change: {
    title: "Quick Lane Change",
    icon: Lane,
    type: "safety",
    level: "abnormal",
  },
  sharp_turn: {
    title: "Sharp Turn",
    icon: SharpTurn,
    type: "safety",
    level: "abnormal",
  },
  fatigue_driving: {
    title: "Fatigue Driving",
    icon: Fatigue,
    type: "safety",
    level: "severe",
  },
  power_off: {
    title: "Power Off",
    icon: PowerOff,
    type: "inspect",
    level: "inspect",
  },
  emergency: {
    title: "Emergency",
    icon: Alert,
    type: "safety",
    level: "severe",
  },
  crash: { title: "Crash", icon: Crash, type: "safety", level: "fatal" },
  ignition_on: {
    title: "Ignition On",
    icon: KeyOn,
    type: "inspect",
    level: "inspect",
  },
  ignition_off: {
    title: "Ignition Off",
    icon: KeyOff,
    type: "inspect",
    level: "inspect",
  },
  mil_alarm: {
    title: "Mil. Alarm",
    icon: Ring,
    type: "green",
    level: "abnormal",
  },
};

const TIMELINE_ICON_SIZE = 20;
const OPT_ROUTE_COLOUR = ["#39f", "#ff3366", "#f3ca3e", "#2dca43"];
const ROUTE_COLOUR = [
  "#652c8e",
  "#3CBAC9",
  "#B57A5B",
  "#9CC4D6",
  "#8ED7A2",
  "#EE895B",
  "#D3675A",
  "#BD3E48",
];

const TIMEZONE = 8;

const API_KEY = {
  new: "19cLc/a2ayasr3mUOOI2YGqazt/YCA3ttEX9PMo5xlo/uACsD5Am3A==",
  old: "QiFwCroxjiMJO7qsg4gpgoNYRllipV4EkR5dFE0lZHx7Fk1rqdFzUg==",
  trip: "8ww1upR6IsWaxKK2BJyfl7boMx9OaKMjfImgcQcG6MbWo3PvzxMIWQ==",
  log: "yQsCG7baLOd83UAoZH/b9BUTSjMguMRUixYFnUyyQI3Yi8otjs4PwQ==",
};

const COURT_ID = "d030eea9-62a1-4e01-890c-9e088960227f";
// const COURT_ID = "9f0d3007-a440-46e9-9c8c-14f648282bad"

// const API_LINK = `https://r1cla6gwy7.execute-api.ap-east-1.amazonaws.com/dev/api/`;
const API_LINK = `/api/`;
// const REPORT_API_LINK = `https://r1cla6gwy7.execute-api.ap-east-1.amazonaws.com/dev/report/offline-report`;
// const API_LINK = `http://localhost:3001/`;

const REPORT_PAGE_LINKS = [
  // {
  //   index: 0,
  //   title: "完整報表",
  //   link: `/trip-detail`,
  //   name: "trip-detail",
  // },
  {
    index: 0,
    title: "trip-replay",
    link: `/trip-replay`,
    name: "trip-replay",
  },
  {
    index: 1,
    title: "trip-viewer",
    link: `/trips`,
    name: "trip-viewer",
  },
];

const PCS_REPORT_PAGE_LINK = [
  {
    index: 0,
    title: "pcs-detail",
    link: "/pcs-detail",
    name: "pcs-detail",
  },
  {
    index: 1,
    title: "pcs-trip",
    link: `/pcs-trip`,
    name: "pcs-trip",
  },
];

const ROUTE_INFO_TAB = 0;
const ROUTE_ROUTE_TAB = 1;
const ROUTE_FARE_TAB = 2;
const ROUTE_CONFIG_TABS = [
  { title: "基本資料設定", value: ROUTE_INFO_TAB },
  { title: "路線設定", value: ROUTE_ROUTE_TAB },
  // { title: "車資設定", value: ROUTE_FARE_TAB },
];

const ROUTE_VERSION_PROD_TAB = 0;
const ROUTE_VERSION_STAGING_PERM_TAB = 1;
const ROUTE_VERSION_STAGING_TEMP_TAB = 2;
const ROUTE_CONFIG_VERSION_TABS = [
  { title: "Production 版本", value: ROUTE_VERSION_PROD_TAB },
  { title: "Staging（永久）版本", value: ROUTE_VERSION_STAGING_PERM_TAB },
  { title: "Staging（臨時）版本", value: ROUTE_VERSION_STAGING_TEMP_TAB },
];

export const GREEN_MARKER = new L.Icon({
  iconUrl:
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});
export const BLUE_MARKER = new L.Icon({
  iconUrl:
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});
export const YELLOW_MARKER = new L.Icon({
  iconUrl:
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-yellow.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});
export const RED_MARKER = new L.Icon({
  iconUrl:
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

// export const OMP_360_API_URL = "http://localhost:7074/api";
export const OMP_360_API_URL = "/api-new";

export default {
  NAV_HEIGHT,
  DESTINATIONS,
  DATA,
  TRIPS,
  FUEL_PER_KM,
  CO2_PER_KM,
  TIME_RANGE_MENU,
  DRAWER_WIDTH,
  ALARM_LIST,
  TIMELINE_ICON_SIZE,
  OPT_ROUTE_COLOUR,
  ROUTE_COLOUR,
  TIMEZONE,
  API_KEY,
  API_LINK,
  COURT_ID,
  REPORT_PAGE_LINKS,
  PCS_REPORT_PAGE_LINK,
  HEAD_BAR_HEIGHT,
  ROUTE_CONFIG_TABS,
  ROUTE_INFO_TAB,
  ROUTE_ROUTE_TAB,
  ROUTE_FARE_TAB,
  ROUTE_CONFIG_VERSION_TABS,
  ROUTE_VERSION_PROD_TAB,
  ROUTE_VERSION_STAGING_PERM_TAB,
  ROUTE_VERSION_STAGING_TEMP_TAB,
  GREEN_MARKER,
  BLUE_MARKER,
  YELLOW_MARKER,
  RED_MARKER,
  OMP_360_API_URL,
};
