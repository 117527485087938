import React from "react";
import { CircularProgress, Paper } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const LoadingPage = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <h3 style={{ margin: "1em" }}>{t("載入中")}...</h3>
    </>
  );
};

export default LoadingPage;
