import {
  AppBar,
  Button,
  Grid,
  IconButton,
  Toolbar,
  Backdrop,
  CircularProgress,
  Menu,
  MenuItem,
  Modal,
  Dialog,
  DialogContent,
  DialogTitle,
  Avatar,
  Tabs,
  Tab,
} from "@material-ui/core";
import {
  Menu as MenuIcon,
  AccountBox,
  ArrowDropDown,
  MenuBook,
  GetApp,
} from "@material-ui/icons";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { withRouter } from "react-router-dom";
import "./style.scss";
import CloseIcon from "@material-ui/icons/Close";

import TranslateIcon from "@material-ui/icons/Translate";
import { isMobileOnly } from "react-device-detect";
import { useTranslation } from "react-i18next";

import { makeStyles, withStyles } from "@material-ui/styles";
import { find } from "lodash";
import BusLogo from "../../assets/front-of-bus.png";
import CustomSnackBar from "../../containers/OperatorConfig/SnackBar";
import AccountModal from "./accountModal";
import logo360 from "../../assets/logo-360.svg";
import NavTabs from "../NavTabs";
import { AuthContext } from "../../App";
import routes, { getSectionItemByName } from "../../utils/route";
import { getPermission } from "../../utils/pagePermission";
import Constants from "../../utils/constants";

const { HEAD_BAR_HEIGHT } = Constants;

const ResponsiveAppbar = (props) => {
  const { section } = props.match.params;
  const { config } = props;
  const { dispatch, state } = useContext(AuthContext);
  const { t, i18n } = useTranslation();
  const {
    showSideBar,
    setShowSideBar,
    lists,
    redirect,
    logout,
    hideSideBarOption,
  } = props;
  const [showMessageDialog, setShowMessageDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [accModalOpen, setAccModalOpen] = useState(false);
  const notiNum = 4;
  const [msg, setMsg] = useState({ show: false, type: "success", msg: "" });

  const handleLogout = () => {
    setAnchorEl(null);
    localStorage.clear();
    window.location.reload();
  };
  const handleLanguageChange = () => {
    if (i18n.language === "zh") {
      i18n.changeLanguage("en");
      localStorage.setItem("lang", "en");
    } else {
      i18n.changeLanguage("zh");
      localStorage.setItem("lang", "zh");
    }
    // window.location.reload();
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAccModal = () => {
    setAnchorEl(null);
    setAccModalOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAccModalOpen(false);
  };

  const handleSwtichContext = (name) => {
    console.log(
      name,
      find(getSectionItemByName(name).section, { link: "/" }).name
    );
    redirect(
      name,
      "/",
      find(getSectionItemByName(name).section, { link: "/" }).name
    );
  };

  return (
    <AppBar
      elevation={0}
      style={{
        height: HEAD_BAR_HEIGHT,
        zIndex: 9999,
        position: "fixed",
        color: "primary",
        borderBottom: "1px solid #e3e3e3",
      }}
    >
      <Toolbar>
        <Grid container style={{ height: "100%" }}>
          <Grid
            item
            md={2}
            xs={2}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            {!hideSideBarOption && (
              <IconButton
                edge="start"
                onClick={() => {
                  console.log("showSideBar", showSideBar);
                  setShowSideBar(!showSideBar);
                }}
              >
                <MenuIcon style={{ color: "#999" }} />
              </IconButton>
            )}

            {(config !== undefined) && (window.screen.width < 420 ? (
              <img src={config?.logoUrl || logo360} className="logo-responsive" />
            ) : (
              <img src={config?.logoUrl || logo360} className="logo" />
            ))}
            {/* <IconButton
              style={{ position: "relative" }}
              onClick={() => {
                setShowMessageDialog(!showMessageDialog);
              }}
              onBlur={() => {
                setShowMessageDialog(false);
              }}
            >
              <Notifications style={{ color: "#fff" }} />
              {notiNum > 0 && <NotiNumberIcon notiNum={notiNum} />}
              {showMessageDialog && <MessageDialog />}
            </IconButton> */}
          </Grid>
          {window.screen.width < 420 && <Grid xs={1}></Grid>}
          <Grid
            item
            md={6}
            sm={6}
            xs={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <NavTabs
              value={section}
              switchContextFunc={handleSwtichContext}
              permission={getPermission(state)}
              config={config}
            />
          </Grid>
          <Grid
            item
            md={4}
            sm={4}
            xs={1}
            style={
              window.screen.width > 420
                ? {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }
                : {
                    display: "flex",
                    alignItems: "center",
                    right: 25,
                    top: 3,
                    position: "absolute",
                  }
            }
          >
            {window.screen.width < 420 ? (
              <></>
            ) : (
              <Button onClick={handleLanguageChange}>
                <TranslateIcon style={{ color: config?.themeColour || "#00645a" }} />
              </Button>
            )}

            {logout &&
              logout[0] &&
              logout.map((button) => {
                return (
                  <div
                    key="logout"
                    // style={{
                    //   marginLeft: "8px",
                    //   marginRight: "8px",
                    //   display: "flex",
                    //   justifyContent: "center",
                    //   alignItems: "center",
                    //   position: "relative",
                    //   height: "100%",
                    // }}
                  >
                    <Button
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      style={{}}
                      onClick={handleClick}
                    >
                      <Avatar style={{}}>
                        {localStorage.getItem("username").substring(0, 2) ||
                          "用戶"}
                        {/* <span
                            className="item-text"
                            style={{
                              color: "white",
                              textTransform: "none",
                            }}
                          ></span> */}
                      </Avatar>

                      {window.screen.width > 420 && (
                        <ArrowDropDown style={{ color: config?.themeColour || "#00645a" }} />
                      )}
                    </Button>

                    <Menu
                      id="simple-menu"
                      style={{ zIndex: 9999 }}
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={handleAccModal}>
                        {/* {t("帳戶資料")} */}
                        {/* <Button onClick={handleAccModal}> */}
                        <AccountBox style={{ color: "#333" }} />
                        <span className="item-text" style={{ marginLeft: 4 }}>
                          {t("帳戶資料")}
                        </span>
                        {/* </Button> */}
                      </MenuItem>
                      <MenuItem onClick={handleLanguageChange}>
                        {/* {t("帳戶資料")} */}
                        {/* <Button onClick={handleAccModal}> */}
                        <TranslateIcon style={{ color: config?.themeColour || "#00645a" }} />
                        <span className="item-text" style={{ marginLeft: 4 }}>
                          {t("切換語言")}
                        </span>
                        {/* </Button> */}
                      </MenuItem>
                      {/* <MenuItem onClick={handleManualModal}> */}
                      {/* {t("帳戶資料")} */}
                      {/* <Button onClick={handleAccModal}> */}
                      {/* <MenuBook style={{ color: "#333" }} />
                          <span className="item-text" style={{ marginLeft: 4 }}>
                            {t("user-manual")}
                          </span> */}
                      {/* </Button> */}
                      {/* </MenuItem> */}
                      <MenuItem onClick={handleLogout}>
                        {/* <Button onClick={handleLogout}> */}
                        {button.blackIcon}
                        <span className="item-text" style={{ marginLeft: 4 }}>
                          {t(button.text)}
                        </span>
                        {/* </Button> */}
                      </MenuItem>
                    </Menu>

                    {button.isActive && (
                      <span
                        style={{
                          position: "absolute",
                          bottom: 0,
                          left: 0,
                          backgroundColor: "#fff",
                          width: "100%",
                          height: "5px",
                        }}
                      />
                    )}
                  </div>
                );
              })}
            <AccountModal
              open={accModalOpen}
              setOpen={setAccModalOpen}
              username={localStorage.getItem("username")}
              setMsg={setMsg}
            />
          </Grid>
        </Grid>
        <CustomSnackBar msg={msg} setMsg={setMsg} />
      </Toolbar>
    </AppBar>
  );
};

const NotiNumberIcon = (props) => {
  const { notiNum } = props;
  return (
    <div
      style={{
        backgroundColor: "#ff5f58",
        color: "#fff",
        borderRadius: "50%",
        height: "24px",
        width: "24px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <span style={{ fontSize: "16px" }}>{notiNum}</span>
    </div>
  );
};

NotiNumberIcon.propTypes = {
  notiNum: PropTypes.number,
};

NotiNumberIcon.defaultProps = {
  notiNum: 0,
};

ResponsiveAppbar.propTypes = {
  showSideBar: PropTypes.bool,
  setShowSideBar: PropTypes.func.isRequired,
  lists: PropTypes.arrayOf(PropTypes.number),
  redirect: PropTypes.func,
};

ResponsiveAppbar.defaultProps = {
  showSideBar: false,
  lists: [],
  redirect: () => {},
};

export default withRouter(ResponsiveAppbar);
