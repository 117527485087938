import { find } from "lodash";
import routes, { getSectionItemByName, routePermissions } from "./route";

/*
 * groupIndex used to sort page group in user config page
 * groupIndex = null :
 * 1) default page : all users has permission
 * 2) group
 *
 *
 * groupId :
 * Indicate which group the page belongs to
 */

export const permissionCodes = (section) => {
  return routePermissions(section);
};

export const getPermissionCode = (section, name) => {
  const permission = find(permissionCodes(section), { name });
  if (permission) {
    return permission.name;
  }
  return null;
};

export const allowRedirect = (section, name, userPermissions) => {
  const code = getPermissionCode(section, name);
  if (userPermissions.includes(code)) {
    return true;
  }
  if (localStorage.getItem("permissions").split(",").includes(code)) {
    return true;
  }
  return false;
};

export const redirect = (section, history, link, name, userPermissions) => {
  if (allowRedirect(section, name, userPermissions)) {
    history.push(`/${getSectionItemByName(section).name}${link}`);
  }
};

export const determineIsTd = (state) => {
  if (state.isTd) {
    return state.isTd;
  }
  if (localStorage.getItem("isTd")) {
    return true;
  }
  return false;
};

export const determineIsMegaOp = (state) => {
  if (state.isMegaOp) {
    return state.isMegaOp;
  }
  if (localStorage.getItem("isMegaOp")) {
    return true;
  }
  return false;
};

export const determineRoutePermissionId = (state) => {
  if (state.routePermissionId) {
    return state.routePermissionId;
  }

  if (localStorage.getItem("routePermissionId")) {
    return localStorage.getItem("routePermissionId");
  }

  return null;
};

export const getOperatorId = (state) => {
  if (state.operatorId) return state.operatorId;

  if (localStorage.getItem("operatorId"))
    return localStorage.getItem("operatorId");

  return null;
};

export const getPermission = (state) => {
  let permissions = [];
  if (state.permissions.length > 0) {
    permissions = state.permissions;
  } else if (localStorage.getItem("permissions")) {
    permissions = localStorage.getItem("permissions").split(",");
  }
  return permissions;
};
