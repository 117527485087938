import React, { lazy } from "react";

import {
  MonetizationOn,
  Visibility,
  Videocam,
  AirportShuttle,
  Restore,
  Assessment,
  Dashboard,
  ExitToApp,
  Notifications,
  Home,
  School,
  Storage,
} from "@material-ui/icons";
import _, { cloneDeep, find, findIndex } from "lodash";

// --- Route Component
const ManageHome = lazy(() => import("../containers/ManageHome"));
const CourtHome = lazy(() => import("../containers/CourtHome"));
const Reserve = lazy(() => import("../containers/Reserve"));
const Shopify = lazy(() => import("../containers/Shopify"));
const ShopifyCustomize = lazy(() => import("../components/ShopifyCustomize"));
const Streaming = lazy(() => import("../containers/Streaming"));
const DeviceConfig = lazy(() => import("../containers/DeviceConfig"));
const DeviceStatus = lazy(() => import("../containers/DeviceStatus"));
const GMBConfiguration = lazy(() => import("../containers/GMBConfiguration"));
const ErrorReport = lazy(() => import("../containers/ErrorReport"));
const RouteManagement = lazy(() => import("../containers/RouteManagement"));
const TripReplay = lazy(() => import("../containers/TripReplay"));
const TripViewer = lazy(() => import("../containers/TripViewer"));
const ArrivalTimetable = lazy(() => import("../containers/ArrivalTimetable"));
const OfflineReport = lazy(() => import("../containers/OfflineReport"));
const MilesReport = lazy(() => import("../containers/MilesReport"));
const OutOfRouteReport = lazy(() => import("../containers/OutOfRouteReport"));
const DailyOperationReport = lazy(() =>
  import("../containers/DailyOperationReport")
);
const TempDisableETA = lazy(() => import("../containers/TempDisableETA"));
const VehicleAllocationReport = lazy(() =>
  import("../containers/VehicleAllocationReport")
);
const OperatorConfig = lazy(() => import("../containers/OperatorConfig"));
const FFNDReport = lazy(() => import("../containers/FFNDReport"));
const RSLReport = lazy(() => import("../containers/RSLReport"));
const JourneyTimeReport = lazy(() =>
  import("../containers/NewJourneyTimeReport")
);
const JourneyTimeExceptionReport = lazy(() =>
  import("../containers/JourneyTimeExceptionReport")
);
const OperationSummaryReport = lazy(() =>
  import("../containers/OperationSummaryReport")
);
const UserConfig = lazy(() => import("../containers/UserConfig"));
const AddRoute = lazy(() => import("../containers/AddRoute"));
const LddInstallation = lazy(() => import("../containers/LddInstallation"));
const ScheduleConfig = lazy(() => import("../containers/ScheduleConfig"));
const ExceptionSummaryReport = lazy(() =>
  import("../containers/ExceptionSummaryReport")
);
const DepartureArrivalReport = lazy(() =>
  import("../containers/DepartureArrivalReport")
);
const SpeedingReport = lazy(() => import("../containers/SpeedingReport"));
const OORDayReport = lazy(() => import("../containers/OORDayReport"));
const OORHourReport = lazy(() => import("../containers/OORHourReport"));
const SosReport = lazy(() => import("../containers/SosReport"));
const GeoFencing = lazy(() => import("../containers/GeoFencing"));

const CardReader = lazy(() => import("../containers/CardReader"));
const Driver = lazy(() => import("../containers/Driver"));
const GroupTag = lazy(() => import("../containers/GroupTag"));
const DriverCard = lazy(() => import("../containers/DriverCard"));
const DriverLogReport = lazy(() => import("../containers/DriverLogReport"));
const IgnitionReport = lazy(() => import("../containers/IgnitionReport"));
const StopEngineReportByVoltage = lazy(() => import("../containers/StopEngineReportByVoltage"));
const WebAppConfig = lazy(() => import("../containers/WebAppConfig"));
const SosConfig = lazy(() => import("../containers/SosConfig"));
const Logout = lazy(() => import("../containers/Logout"));
const RevenueHome = lazy(() => import("../containers/RevenueHome"));

const AIHome = lazy(() => import("../containers/AIHome"));
const NotificationCenter = lazy(() =>
  import("../containers/NotificationCenter")
);

const PCSDetail = lazy(() => import("../containers/PCSDetail"));
const PCSTrip = lazy(() => import("../containers/PCSTrip"));
const PCSCourtHome = lazy(() => import("../containers/PCSCourtHome"));
const KMBDemo = lazy(() => import("../containers/KMBDemo"));
const StreamPlayBack = lazy(() => import("../containers/StreamPlayback"));
const PCSDashboard = lazy(() => import("../containers/PCSDashboard"));

const RouteConfig = lazy(() => import("../containers/RouteConfigV2"));

const EtaAppSettingConfig = lazy(() =>
  import("../containers/EtaAppSettingConfig")
);

const SchoolbusHome = lazy(() => import("../containers/schoolbus/SchoolbusHome"));
const SchoolbusImport = lazy(() => import("../containers/schoolbus/SchoolbusImport"));
const SchoolbusCard = lazy(() => import("../containers/schoolbus/SchoolbusCard"));
const SchoolbusStudent = lazy(() => import("../containers/schoolbus/SchoolbusStudent"));
const SchoolbusDataSummary = lazy(() => import("../containers/schoolbus/SchoolbusDataSummary"));
const SchoolbusAttendance = lazy(() => import("../containers/schoolbus/SchoolbusAttendance"));

const routes = [
  {
    name: "manage",
    section: [
      // text: Name in menu,
      // link: Link to go when clicked in menu,
      // path: Path to overload the container,
      // name: ID,
      // component: Container component,
      {
        text: "管理主頁",
        link: `/`,
        name: "manage-home",
        icon: Home,
        component: ManageHome,
      },
      {
        text: "實時監測",
        link: `/dashboard`,
        name: "court-home",
        icon: Visibility,
        component: CourtHome,
      },
      {
        text: "實時串流",
        name: "live-stream",
        icon: Videocam,
        children: [
          {
            text: "串流影像",
            link: `/streaming`,
            name: "streaming",
            component: Streaming,
          },
        ],
      },
      {
        text: "到站時間表",
        link: `/arrival-timetable`,
        name: "arrival-timetable",
        icon: AirportShuttle,
        component: ArrivalTimetable,
      },
      {
        text: "行車紀錄",
        name: "trip-record",
        icon: Restore,
        children: [
          {
            text: "行車軌跡",
            link: `/trip-replay`,
            path: "/trip-replay/:tripId",
            name: "trip-replay",
            component: TripReplay,
          },
          {
            text: "行程追溯",
            link: `/trips`,
            name: "trip-viewer",
            component: TripViewer,
          },
        ],
      },
      {
        text: "營運報表",
        name: "operating-report",
        icon: Assessment,
        children: [
          {
            text: "營運總覽",
            link: `/operation-summary-report`,
            name: "operation-summary-report",
            component: OperationSummaryReport,
          },
          {
            text: "里數報表",
            link: `/miles-report`,
            name: "miles-report",
            component: MilesReport,
          },
          {
            text: "車輛編配報表",
            link: `/vehicle-allocation-report`,
            name: "vehicle-allocation-report",
            component: VehicleAllocationReport,
          },
          {
            text: "行車時間報表",
            link: `/journey-time-report`,
            name: "journey-time-report",
            component: JourneyTimeReport,
          },
          {
            text: "班次報表",
            link: `/departure-arrival-report`,
            name: "departure-arrival-report",
            component: DepartureArrivalReport,
          },
          {
            text: "司機拍卡報表",
            link: `/driver-log-report`,
            name: "driver-log-report",
            component: DriverLogReport,
          },
          {
            text: "開車報表",
            link: `/ignition-report`,
            name: "ignition-report",
            component: IgnitionReport,
          },
          {
            text: "開/熄車報表 (電壓)",
            link: `/stop-engine-report-by-voltage`,
            name: "stop-engine-report-by-voltage",
            component: StopEngineReportByVoltage,
          },
        ],
      },
      {
        text: "服務異常報表",
        name: "exception-reports",
        icon: Assessment,
        children: [
          {
            text: "服務異常總覽",
            link: `/exception-summary-report`,
            name: "exception-summary-report",
            component: ExceptionSummaryReport,
          },
          {
            text: "偏離路線報表",
            link: `/out-of-route-report`,
            name: "out-of-route-report",
            component: OutOfRouteReport,
          },
          {
            text: "行車時間異常報表",
            link: `/journey-time-exception-report`,
            name: "journey-time-exception-report",
            component: JourneyTimeExceptionReport,
          },
          {
            text: "全車隊未能接收數據報表",
            link: `/ffnd-report`,
            name: "ffnd-report",
            component: FFNDReport,
          },
          {
            text: "同一位置報表",
            link: `/rsl-report`,
            name: "rsl-report",
            component: RSLReport,
          },
          {
            text: "超速報表 (只供參考)",
            link: `/speeding-report`,
            name: "speeding-report",
            component: SpeedingReport,
          },
          {
            text: "營運時間異常報表",
            link: `/oor-hour-report`,
            name: "oor-hour-report",
            component: OORHourReport,
          },
          {
            text: "營運日子異常報表",
            link: `/oor-day-report`,
            name: "oor-day-report",
            component: OORDayReport,
          },
          {
            text: "求救報表",
            link: `/sos-report`,
            name: "sos-report",
            component: SosReport,
          },
        ],
      },
      {
        text: "系統異常報表",
        name: "system-exception-report",
        icon: Assessment,
        children: [
          {
            text: "離線報表",
            link: `/offline-report`,
            name: "offline-report",
            component: OfflineReport,
          },
        ],
      },
      {
        text: "管理",
        name: "management",
        icon: Dashboard,
        children: [
          {
            text: "收貨鳥",
            link: `/shopify`,
            name: "shopify",
            component: Shopify,
          },
          {
            text: "電子圍欄",
            link: `/geo-fencing`,
            path: "/geo-fencing/:pslId?",
            name: "geo-fencing",
            component: GeoFencing,
          },
          {
            text: "新增路線v2",
            link: `/route-config`,
            name: "route-config",
            component: RouteConfig,
          },
          {
            text: "新增路線",
            link: `/add-route`,
            name: "add-route",
            component: AddRoute,
          },
          {
            text: "路線設定",
            link: "/route-management",
            path: "/route-management/:pslId?/:routeId?/:routeSeq?/:version?",
            name: "route-management",
            component: RouteManagement,
          },
          {
            text: "營運商設定",
            link: `/operator-config`,
            name: "operator-config",
            component: OperatorConfig,
            isTd: true,
          },
          {
            text: "裝置設定",
            link: `/device-config`,
            name: "device-config",
            component: DeviceConfig,
          },
          {
            text: "車輛設定",
            link: `/vehicle-config`,
            name: "vehicle-config",
            component: GMBConfiguration,
          },
          {
            text: "用戶設定",
            link: `/user-config`,
            name: "user-config",
            component: UserConfig,
          },
          {
            text: "班次設定",
            link: `/schedule-config`,
            name: "schedule-config",
            component: ScheduleConfig,
          },
          {
            text: "讀卡器設定",
            link: `/card-reader`,
            name: "card-reader",
            component: CardReader,
          },
          {
            text: "司機設定",
            link: `/driver`,
            name: "driver",
            component: Driver,
          },
          {
            text: "證件設定",
            link: `/driver-card`,
            name: "driver-card",
            component: DriverCard,
          },
          {
            text: "車輛標籤設定",
            link: `/group-tag`,
            name: "group-tag",
            component: GroupTag,
          },
          {
            text: "應用頁面設定",
            link: `/web-app-config`,
            name: "web-app-config",
            component: WebAppConfig,
          },
          {
            text: "臨時停用到站預報",
            link: `/temp-disable-eta`,
            name: "temp-disable-eta",
            component: TempDisableETA,
            isTd: true,
          },
          {
            text: "求救通知",
            link: `/sos-config`,
            name: "sos-config",
            component: SosConfig,
          },
          {
            text: "Notification Center",
            link: "/notification-center",
            name: "notification-center",
            icon: Notifications,
            component: NotificationCenter,
          },
          {
            text: "Eta App Setting Config",
            link: "/eta-app-setting-config",
            name: "eta-app-setting-config",
            component: EtaAppSettingConfig,
          },
        ],
      },
      {
        text: "登出",
        link: `/logout`,
        name: "logout",
        icon: ExitToApp,
        component: Logout,
      },
    ],
  },
  {
    name: "revenue",
    section: [
      {
        text: "收益主頁",
        link: `/`,
        name: "revenue-home",
        icon: Visibility,
        component: RevenueHome,
      },
      {
        text: "收益",
        name: "earn",
        icon: MonetizationOn,
        children: [
          {
            text: "預約服務",
            link: `/reserve`,
            name: "reserve",
            component: Reserve,
          },
        ],
      },
      {
        text: "登出",
        link: `/logout`,
        name: "logout",
        icon: ExitToApp,
        component: Logout,
      },
    ],
  },
  {
    name: "ai",
    section: [
      {
        text: "AI 主頁",
        link: "/",
        name: "ai-home",
        icon: Visibility,
        component: AIHome,
      },
      {
        text: "乘客人流統計",
        name: "passenger-flow",
        icon: Visibility,
        children: [
          {
            text: "客流圖表",
            link: `/pcs-graph`,
            name: "pcs-graph",
            component: PCSDashboard,
          },
          {
            text: "實時監測",
            link: `/pcs-dashboard`,
            name: "pcs-dashboard",
            component: PCSCourtHome,
          },
          {
            text: "行程客流",
            link: `/pcs-trip`,
            name: "pcs-trip",
            component: PCSTrip,
          },
          {
            text: "客流詳情",
            link: `/pcs-detail`,
            path: `/pcs-detail/:tripId?`,
            name: "pcs-detail",
            component: PCSDetail,
          },
          {
            text: "KMB",
            link: `/kmb-demo`,
            name: "kmb-demo",
            component: KMBDemo,
          },
          {
            text: "Playback",
            link: `/stream-playback`,
            name: "stream-playback",
            component: StreamPlayBack,
          },
        ],
      },
      {
        text: "登出",
        link: `/logout`,
        name: "logout",
        icon: ExitToApp,
        component: Logout,
      },
    ],
  },
  {
    name: "schoolbus",
    section: [
      {
        text: "校巴主頁",
        link: "/",
        name: "schoolbus-home",
        icon: School,
        component: SchoolbusHome,
      },
      {
        text: "數據管理",
        name: "schoolbus-data",
        icon: Storage,
        children: [
          {
            text: "匯入數據",
            link: "/import",
            name: "schoolbus-import",
            component: SchoolbusImport,
          },
          {
            text: "數據總覽",
            link: "/data-summary",
            name: "schoolbus-data-summary",
            component: SchoolbusDataSummary,
          },
          {
            text: "智能卡",
            link: "/card",
            name: "schoolbus-card",
            component: SchoolbusCard,
          },
          {
            text: "學生/保母/司機",
            link: "/student-nanny-driver",
            name: "schoolbus-student-nanny-driver",
            component: SchoolbusStudent,
          },
          {
            text: "考勤紀錄",
            link: "/attendance",
            name: "schoolbus-attendance",
            component: SchoolbusAttendance,
          }
        ]
      },
      {
        text: "登出",
        link: `/logout`,
        name: "logout",
        icon: ExitToApp,
        component: Logout,
      },
    ]
  }
  // {
  //   name: "notification",
  //   section: [
  //     {
  //       text: "Notification Center",
  //       link: "/",
  //       name: "notification-center",
  //       icon: Notifications,
  //       component: NotificationCenter,
  //     },
  //     {
  //       text: "登出",
  //       link: `/logout`,
  //       name: "logout",
  //       icon: ExitToApp,
  //       component: Logout,
  //     },
  //   ],
  // },
];

export const getSectionItemByName = (name) => {
  return find(routes, { name });
};

export const flattenRoutesToPages = () => {
  return _.chain(routes)
    .map((route) => route.section)
    .flatten()
    .value();
};

export const routePermissions = (section) => {
  try {
    const items =
      section === undefined
        ? flattenRoutesToPages()
        : getSectionItemByName(section).section;
    const flattened = [
      { id: 0, name: "login", isTd: false },
      { id: 1, name: "court-root", isTd: false },
    ];
    let k = 2;
    for (let i = 0; i < items.length; i++) {
      flattened.push({
        id: k,
        isTd: false,
        ...items[i],
      });
      k++;
      if (!items[i].children) continue;
      for (let j = 0; j < items[i].children.length; j++) {
        flattened.push({
          id: k,
          isTd: false,
          ...items[i].children[j],
        });
        k++;
      }
    }
    return flattened;
  } catch (err) {
    return null;
  }
};

export const routesToMenuItems = (section, config) => {
  
  const items = getSectionItemByName(section);
  return items.section.map((route) => {
    const { text, link, name, children = [] } = route;
    return {
      whiteIcon: <route.icon style={{ color: "#fff" }} fontSize="small" />,
      blackIcon: <route.icon style={{ color: config?.themeColour || "#00645a" }} fontSize="small" />,
      selectedIcon: <route.icon style={{ color: "#fff" }} fontSize="small" />,
      text,
      link,
      name,
      isActive: false,
      isExpand: true,
      children: children.map((childRoute) => {
        return {
          ...childRoute,
          isActive: false,
        };
      }),
    };
  });
};

const getTargetRouteFromPath = (pathname, tempLists) => {
  const pathnameSegmentArray = pathname.split("/");

  // Handle one level root or two level url
  const pathnameSegment = `/${
    pathnameSegmentArray.length < 3 ? "" : pathnameSegmentArray[2]
  }`;
  console.log("pathnameSegment", pathnameSegment);
  const routeIndex = findIndex(tempLists, {
    link: pathnameSegment,
  });
  if (routeIndex !== -1) {
    // One level link
    return {
      index: routeIndex,
      subIndex: -1,
      item: tempLists[routeIndex],
    };
  }
  for (let i = 0; i < tempLists.length; i++) {
    // Two level link
    if (tempLists[i].children?.length) {
      const childRouteIndex = findIndex(tempLists[i].children, {
        link: pathnameSegment,
      });
      if (childRouteIndex !== -1) {
        return {
          index: i,
          subIndex: childRouteIndex,
          item: tempLists[i].children[childRouteIndex],
        };
      }
    }
  }
  return null;
};

export const getActiveIndices = (pathname, tempLists) => {
  const { index = -1, subIndex = -1 } = getTargetRouteFromPath(
    pathname,
    tempLists
  );
  return [index, subIndex];
};

export const getPermissionCodes = () => {};

export default routes;
