import { makeStyles, Tab, Tabs, withStyles } from "@material-ui/core";
import {
  DirectionsBus,
  DirectionsCar,
  MonetizationOn,
  Phone,
  Visibility,
  Notifications,
  School
} from "@material-ui/icons";
import React from "react";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import color from "color";

const NavTabs = ({ value, switchContextFunc, permission, config }) => {
  const { t, i18n } = useTranslation();
  const handleChange = (event, newValue) => {
    switchContextFunc(newValue);
  };

  const selectedColour = color(config?.themeColour || "#00645A").darken(0.1).hex();

  const AntTabs = withStyles({
    root: {
      // borderBottom: "1px solid #e8e8e8",
      height: "100%",
      display: "flex",
      alignItems: "flex-end",
    },
    scroller: {
      height: "100%",
    },
    flexContainer: {
      height: "100%",
    },
    indicator: {
      backgroundColor: selectedColour,
    },
  })(Tabs);

  const AntTab = withStyles((theme) => ({
    root: {
      textTransform: "none",
      minWidth: 72,
      color: "grey",
      display: "flex",
      padding: 0,
      marginRight: 12,
      alignItems: "flex-end",
      "&:hover": {
        color: selectedColour,
        opacity: 1,
      },
      "&$selected": {
        color: selectedColour,
      },
      "&:focus": {
        color: selectedColour,
      },
      fontWeight: "bold",
    },
    wrapper: {
      height: "100%",
    },
    selected: {
      border: 0,
      borderBottom: `5px solid ${selectedColour}`,
    },
  }))((props) => <Tab disableRipple {...props} />);
  
  const AntTabRes = withStyles((theme) => ({
    root: {
      textTransform: "none",
      minWidth:60,
      color: "grey",
      display:'flex',
      padding: 0,
      marginRight: 1,
      alignItems: "flex-end",
      "&:hover": {
        color: selectedColour,
        opacity: 1,
      },
      "&$selected": {
        color: selectedColour,
      },
      "&:focus": {
        color: selectedColour,
      },
      fontWeight: "bold",
    },
    wrapper: {
      height: "100%",
    },
    selected: {
      border: 0,
      borderBottom: `3px solid ${selectedColour}`,
    },
  }))((props) => <Tab disableRipple {...props} />);
  
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    demo2: {
      backgroundColor: "#2e1534",
    },
  }));
  
  const IconTab = ({ name, tabIcon: Icon }) => {
    return (
      <div className="icon-tab" style={{}}>
        <Icon fontSize="small" className="tab-icon" />
        {name}
      </div>
    );
  };
  const IconTabRes = ({ name, tabIcon: Icon }) => {
    return (
      <div className="icon-tab" style={{}}>
        <Icon fontSize="small" className="tab-icon" />
        
      </div>
    );
  };



  return (
    <>
    {window.screen.width > 420 ?  (
    <AntTabs
      value={value}
      onChange={handleChange}
      aria-label="simple tabs example"
   
    >
      {permission.includes("manage-home") && (
        <AntTab
          value="manage"
          label={<IconTab name={t("management")} tabIcon={DirectionsBus} />}
        />
      )}
      {permission.includes("revenue-home") && (
        <AntTab
          value="revenue"
          label={<IconTab name={t("earn")} tabIcon={MonetizationOn} />}
        />
      )}
      {permission.includes("ai-home") && (
        <AntTab
          value="ai"
          label={<IconTab name={t("ai")} tabIcon={Visibility} />}
        />
      )}
      {permission.includes("schoolbus-home") && (
        <AntTab
          value="schoolbus"
          label={<IconTab name={t("schoolbus")} tabIcon={School} />}
        />
      )}
      {/* <AntTab
        value="notification"
        label={<IconTab name={t("notification")} tabIcon={Notifications} />}
      /> */}
    </AntTabs>):(
       <AntTabs
       value={value}
       onChange={handleChange}
       aria-label="simple tabs example"
       variant="scrollable"
    
     >
       {permission.includes("manage-home") && (
         <AntTabRes
           value="manage"
           label={<IconTabRes name={t("management")} tabIcon={DirectionsBus} />}
         />
       )}
       {permission.includes("revenue-home") && (
         <AntTabRes
           value="revenue"
           label={<IconTabRes name={t("earn")} tabIcon={MonetizationOn} />}
         />
       )}
       {permission.includes("ai-home") && (
         <AntTabRes
           value="ai"
           label={<IconTabRes name={t("ai")} tabIcon={Visibility} />}
         />
       )}
       {permission.includes("schoolbus-home") && (
         <AntTabRes
           value="schoolbus"
           label={<IconTabRes name={t("schoolbus")} tabIcon={School} />}
         />
       )}
       {/* <AntTab
         value="notification"
         label={<IconTab name={t("notification")} tabIcon={Notifications} />}
       /> */}
     </AntTabs>
    )}
    </>
  );
};

export default NavTabs;
