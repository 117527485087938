import { Snackbar } from "@material-ui/core";
import React from "react";
import MuiAlert from "@material-ui/lab/Alert";

const CustomSnackBar = ({ msg, setMsg }) => {
  return (
    <Snackbar
      open={msg?.show}
      autoHideDuration={3000}
      onClose={() => setMsg({ show: false, type: msg.type, msg: "" })}
    >
      <Alert
        onClose={() => setMsg({ show: false, type: msg.type, msg: "" })}
        severity={msg.type}
      >
        {msg.msg}
      </Alert>
    </Snackbar>
  );
};

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

export default CustomSnackBar;
