import Constants from "./constants";

const { API_LINK } = Constants;

class Auth {
  constructor() {
    this.authenticated = false;
  }

  async authorized(username, password) {
    const rawStatus = await fetch(`/api/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, password }),
    });
    const status = await rawStatus.json();
    // console.log("==== status", status);
    this.authenticated = !status.data;
    return status.data ? { ...status.data, code: 200 } : { code: status.code };
  }

  setAuthenticated(val) {
    this.authenticated = val;
  }

  logout(cb) {
    this.authenticated = false;
    cb();
  }

  isAuthenticated() {
    return this.authenticated;
  }
}

export default new Auth();
