import {
  Apartment,
  CreditCard,
  Dashboard,
  DirectionsBus,
  DirectionsCar,
  MonetizationOn,
  PanTool,
  Restore,
  StayCurrentPortrait,
  Videocam,
  Visibility,
} from "@material-ui/icons";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AirportShuttleIcon from "@material-ui/icons/AirportShuttle";
import { cloneDeep, find, findIndex } from "lodash";
import React, { useContext, useEffect, useState, useMemo } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import { AuthContext } from "../../App";
import useWindowDimensions from "../../utils/dimension";
import Constants from "../../utils/constants";

const { HEAD_BAR_HEIGHT } = Constants;

import {
  getPermission,
  getPermissionCode,
  redirect,
} from "../../utils/pagePermission";
import ResponsiveAppbar from "../ResponsiveAppbar";
import ResponsiveDrawer from "../ResponsiveDrawer";
import { getActiveIndices, routesToMenuItems } from "../../utils/route";

const MenuBarLayout = (props) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { height, width } = useWindowDimensions();
  const { children, match, location, config } = props;
  const [showSideBar, setShowSideBar] = useState(false);
  const { section } = props.match.params;
  const { state } = useContext(AuthContext);
  const hideHeader = localStorage.getItem("hideHeader");
  const [hideSideBarOption, setHideSideBarOption] = useState(true);
  const [initialisedSection, setInitialisedSection] = useState(section);

  const permissionGranted = (code) => {
    let permissions = [];
    if (state.permissions.length > 0) {
      permissions = state.permissions;
    } else if (localStorage.getItem("permissions")) {
      permissions = localStorage.getItem("permissions").split(",");
    }
    return permissions.includes(code);
  };

  const [filterList, setFilterList] = useState([]);

  const initActiveState = (tempLists) => {
    return tempLists.map((list) => {
      list.isActive = false;
      list.children = list.children.map((child) => {
        child.isActive = false;
        return child;
      });
      return list;
    });
  };

  const markActiveListItem = (tempFilterList) => {
    const tempLists = initActiveState(tempFilterList);
    // Report Related
    // if (location.pathname.includes("/trip-detail")) {
    //   const index = findIndex(lists, { text: "車輛報表" });
    //   tempLists[index].isActive = true;
    //   const subIndex = findIndex(tempLists[index].children, {
    //     text: "完整報表",
    //   });
    //   tempLists[index].children[subIndex].isActive = true;
    // } else
    const [index, subIndex] = getActiveIndices(location.pathname, tempLists);
    tempLists[index].isActive = true;
    if (subIndex !== -1) {
      tempLists[index].children[subIndex].isActive = true;
    }
    return tempLists;
  };

  const triggerRedirect = (sectionName, link, name) => {
    const permissions = getPermission(state);
    redirect(sectionName, props.history, link, name, permissions);
  };

  const lists = useMemo(() => {
    if (config === undefined) {
      return [];
    }
    try {
      return routesToMenuItems(section, config);
    } catch (err) {
      history.replace("/forbidden");
    }
  }, [section, config]);

  useEffect(() => {
    try {
      if (
        lists?.length > 0 &&
        (filterList.length === 0 || initialisedSection !== section)
      ) {
        const tempFilterList = lists
          .map((ele) => {
            if (permissionGranted(getPermissionCode(section, ele.name))) {
              ele.children = ele.children.filter((child) =>
                permissionGranted(getPermissionCode(section, child.name))
              );
              return ele;
            }
            return null;
          })
          .filter((ele) => !!ele);
        setFilterList(markActiveListItem(tempFilterList));
        setInitialisedSection(section);
      }
    } catch (err) {
      history.replace("/forbidden");
    }
  }, [location, section, config]);

  useEffect(() => {
    try {
      if (filterList.length > 0 && initialisedSection === section) {
        setFilterList(markActiveListItem(filterList));
      }
    } catch (err) {
      history.replace("/forbidden");
    }
  }, [location]);

  useEffect(() => {
    if (width > 960) {
      setHideSideBarOption(true);
    } else {
      setHideSideBarOption(false);
    }
  }, [width]);

  const logoutButton = find(lists, { name: "logout" });
  const NormalBar = () => {
    return (
      <>
        <ResponsiveAppbar
          showSideBar={showSideBar}
          setShowSideBar={setShowSideBar}
          hideSideBarOption={hideSideBarOption}
          // lists={filterList}
          logout={[logoutButton]}
          // lists={lists}
          setLists={setFilterList}
          redirect={triggerRedirect}
          config={config}
        />
      </>
    );
  };
  if (!hideHeader) {
    return (
      <>
        {/* {isTd && <TdBar />}
      {isHp && <HpBar />}
      {!isTd && !isHp && <NormalBar />} */}
        <NormalBar />
        <div
          style={{
            height: `calc(${height}px - ${HEAD_BAR_HEIGHT}px)`,
            marginTop: HEAD_BAR_HEIGHT,
            width: "100vw",
          }}
        >
          <Grid container style={{ width: "100%", height: "100%" }}>
            {hideSideBarOption ? (
              <Grid
                item
                xs={2}
                md={2}
                style={{
                  height: `calc(${height}px - ${HEAD_BAR_HEIGHT}px)`,
                  overflow: "auto",
                  borderRight: "1px solid #e3e3e3",
                }}
              >


                <ResponsiveDrawer
                  showSideBar={showSideBar}
                  hideSideBarOption={hideSideBarOption}
                  setShowSideBar={setShowSideBar}
                  lists={filterList}
                  logout={[logoutButton]}
                  // lists={lists}
                  setLists={setFilterList}
                  redirect={triggerRedirect}
                  config={config}
                />


                
              </Grid>
            ) : (
              <ResponsiveDrawer
                showSideBar={showSideBar}
                hideSideBarOption={hideSideBarOption}
                setShowSideBar={setShowSideBar}
                lists={filterList}
                logout={[logoutButton]}
                // lists={lists}
                setLists={setFilterList}
                redirect={triggerRedirect}
                config={config}
              />
            )}
            <Grid
              item
              xs={hideSideBarOption ? true : 12}
              md={hideSideBarOption ? true : 12}
              style={{
                height: `calc(${height}px - 62px)`,
                overflow: "auto",
              }}
            >
              {children}
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
  return (
    <Grid
      item
      xs={12}
      md={12}
      style={{
        height: `calc(${height}px - 62px)`,
        overflow: "auto",
      }}
    >
      {children}
    </Grid>
  );
};

export default withRouter(MenuBarLayout);
