/* eslint-disable no-nested-ternary */
import {
  Collapse,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Fragment
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import React, { useContext, useEffect } from "react";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import useWindowDimensions from "../../utils/dimension";
import { AuthContext } from "../../App";
import color from "color";
import Constants from "../../utils/constants";

const { HEAD_BAR_HEIGHT } = Constants;

const ResponsiveDrawer = (props) => {
  const { config } = props;
  
  const useStyles = makeStyles({
    root: {
      borderRadius: 8,
      width: "90%",
      margin: "auto",
      marginBottom: 4,
      "&$selected": {
        backgroundColor: config?.themeColour || "#00645a",
        color: "white",
        "&:hover": {
          backgroundColor: color(config?.themeColour || "#00645a").darken(0.5).hex(),
        },
      },
    },
    selected: {},
  });

  const { t, i18n } = useTranslation();
  const { height } = useWindowDimensions();
  const classes = useStyles();
  const { section } = props.match.params;

  const {
    showSideBar,
    setShowSideBar,
    hideSideBarOption,
    lists,
    setLists,
    redirect,
    logout,
  } = props;

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
  };

  const toggleExpandCollapse = (index, expand) => {
    const newLists = lists;
    newLists[index].isExpand = expand;
    setLists([...newLists]);
  };

  const onClickToRedirect = (link, name) => {
    redirect(section, link, name);
    setShowSideBar(false);
  };
  useEffect(() => {
    console.log(showSideBar);
  }, [showSideBar]);

  const content = () => (
    <List
      // className={classes.root}
      style={{
        height: `calc(${height}px - 86px)`,
        width: "100%",
        paddingTop: 16,
      }}
    >
      {lists &&
        lists.length > 0 &&
        lists.map((listEle, index) => {
          if (listEle.name === "current-map") {
            return (
              <React.Fragment key={`${listEle.name}-root`}>
                <ListItem
                  key={`${listEle.name}-dashboard`}
                  button
                  dense
                  selected={listEle.isActive}
                  classes={{
                    root: classes.root,
                    selected: classes.selected,
                  }}
                  onClick={() => onClickToRedirect(listEle.link, listEle.name)}
                >
                  <ListItemIcon style={{ minWidth: 30 }}>
                    {listEle.isActive && listEle.children.length === 0
                      ? listEle.selectedIcon
                      : listEle.blackIcon}
                  </ListItemIcon>
                  <ListItemText
                    // className={listEle.isActive ? "selected" : ""}
                    primary={t(listEle.name)}
                  />
                </ListItem>
                {/* <Divider /> */}
              </React.Fragment>
            );
          }
          return (
            <React.Fragment key={`${listEle.name}-root`}>
              {/* <Divider key="d1" /> */}
              <ListItem
                key={`${listEle.name}`}
                button
                dense
                // Only highlight the parent if no children in function tree
                selected={listEle.isActive && !listEle.children.length > 0}
                classes={{
                  root: classes.root,
                  selected: classes.selected,
                }}
                onClick={() => {
                  if (listEle.link)
                    onClickToRedirect(listEle.link, listEle.name);
                }}
                style={{ minWidth: 150 }}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  {listEle.isActive && listEle.children.length === 0
                    ? listEle.selectedIcon
                    : listEle.blackIcon}
                </ListItemIcon>
                <ListItemText
                  // className={listEle.isActive ? "selected" : ""}
                  primary={t(listEle.name)}
                />
                {listEle.children.length > 0 ? (
                  listEle.isExpand ? (
                    <IconButton
                      size="small"
                      onClick={(event) => {
                        event.stopPropagation();
                        toggleExpandCollapse(index, false);
                      }}
                    >
                      <ExpandLess />
                    </IconButton>
                  ) : (
                    <IconButton
                      size="small"
                      onClick={(event) => {
                        event.stopPropagation();
                        toggleExpandCollapse(index, true);
                      }}
                    >
                      <ExpandMore />
                    </IconButton>
                  )
                ) : null}
              </ListItem>
              {listEle.children.length > 0 && (
                <Collapse in={listEle.isExpand}>
                  <List>
                    {listEle.children
                      .map((children) => {
                        // HOT FIX : hiding some children in socif account
                        // showing some children in socif accounting
                        return (
                          <ListItem
                            key={`${children.name}`}
                            button
                            dense
                            selected={children.isActive}
                            classes={{
                              root: classes.root,
                              selected: classes.selected,
                            }}
                            onClick={() =>
                              onClickToRedirect(children.link, children.name)
                            }
                          >
                            <ListItemIcon style={{ minWidth: 30 }} />
                            <ListItemText>{t(children.name)}</ListItemText>
                          </ListItem>
                        );
                      })
                      .filter((ele) => !!ele)}
                  </List>
                </Collapse>
              )}
            </React.Fragment>
          );
        })}
    </List>
  );

  if (hideSideBarOption) {
    return content();
  }
  return (
    <Drawer
      PaperProps={{
        style: {
          backgroundColor: "#f4f5f7",
          marginTop: HEAD_BAR_HEIGHT,
          padding: "0 16px",
          height: `calc(${height}px - ${HEAD_BAR_HEIGHT}px)`,
        },
      }}
      style={{ zIndex: 9998 }}
      anchor="left"
      open={showSideBar}
      onClose={() => {
        setShowSideBar(false);
      }}
    >
      {content()}
    </Drawer>
  );
};

export default withRouter(ResponsiveDrawer);
