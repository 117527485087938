import { useState, useEffect } from "react";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener(
      "resize",
      (event) => {
        const origin = event?.srcElement?.origin;
        if (
          origin !== "https://omp.etagmb.gov.hk" &&
          origin !== "https://td-omp-dev.socif.co" &&
          origin !== "http://localhost:3000"
        )
          return;
        handleResize();
      },
      false
    );
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}
