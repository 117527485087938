import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  zh: {
    translation: {
      // ======== General ========
      名稱: "名稱",
      啟用: "啟用",
      新增: "新增",
      簡體中文: "簡體中文",
      繁體中文: "繁體中文",
      英文: "英文",
      路線: "路線",
      車輛: "車輛",
      // ======= LOGIN =======
      密碼: "密碼",
      專線車輛實時到站系統網上管理平台: "智能客運 360 管理平台",
      用戶名稱: "用戶名稱",
      用戶名稱或密碼錯誤: "密碼錯誤",
      登入: "登入",
      請輸入用戶名稱及密碼: "請輸入用戶名稱及密碼",
      // ======= MENU =======
      "add-route": "新增路線",
      "arrival-timetable": "到站時間表",
      "card-reader": "讀卡器設定",
      "court-home": "實時監測",
      "departure-arrival-report": "班次報表",
      "device-config": "裝置設定",
      "driver-card": "證件設定",
      "driver-log-report": "司機拍卡報表",
      "exception-reports": "服務異常報表",
      "exception-summary-report": "服務異常總覽",
      "ffnd-report": "全車隊未能接收數據報表",
      "geo-fencing": "電子圍欄",
      "vehicle-config": "車輛設定",
      "group-tag": "車輛標籤設定",
      "ignition-report": "開車報表",
      "stop-engine-report-by-voltage": "開/熄車報表 (電壓)",
      "journey-time-exception-report": "行車時間異常報表",
      "journey-time-report": "行車時間報表",
      "live-stream": "實時串流",
      "miles-report": "里數報表",
      "offline-report": "離線報表",
      "oor-day-report": "營運日子異常報表",
      "oor-hour-report": "營運時間異常報表",
      "operating-report": "營運報表",
      "operation-summary-report": "營運總覽",
      "operator-config": "營運商設定",
      "out-of-route-report": "偏離路線報表",
      "route-management": "路線設定",
      "rsl-report": "同一位置報表",
      "schedule-config": "班次設定",
      "speeding-report": "超速報表 (只供參考)",
      "sos-report": "求救報表",
      "sos-config": "求救通知",
      "system-exception-report": "系統異常報表",
      "temp-disable-eta": "臨時停用到站預報",
      "trip-record": "行車紀錄",
      "trip-replay": "行車軌跡",
      "trip-viewer": "行程追溯",
      "user-config": "用戶設定",
      "vehicle-allocation-report": "車輛編配報表",
      "web-app-config": "應用頁面設定",
      "revenue-home": "收益主頁",
      "ai-home": "AI主頁",
      "schoolbus-home": "校巴主頁",
      "schoolbus-data": "數據管理",
      "schoolbus-import": "數據匯入",
      "schoolbus-card": "智能卡",
      "schoolbus-student-nanny-driver": "學生/保母/司機",
      "schoolbus-data-summary": "數據總覽",
      "schoolbus-attendance": "考勤紀錄",
      "pcs-trip": "行程客流",
      ai: "人工智能 AI",
      schoolbus: "校巴",
      "passenger-flow": "乘客人流統計",
      "pcs-dashboard": "實時監測",
      "pcs-graph": "客流圖表",
      "kmb-demo": "巴士實時監測",
      "stream-playback": "串流重播",
      "shopify-customize": "Shopify Webapp 設定",
      playlist: "播放清單",
      "start-time": "開始時間",
      "end-time": "結束時間",
      "now-playing": "正在播放",
      "no-video": "暫無影片",
      autoplay: "自動播放",
      driver: "司機設定",
      earn: "收益",
      logout: "登出",
      management: "管理",
      reserve: "預約服務",
      bookingRoute: "預約路線",
      專線車輛實時到站系統: "專線車輛實時到站系統",
      網上管理平台: "智能客運 360 管理平台",
      網上管理平台Short: "智能客運 360 管理平台",
      裝置狀態: "裝置狀態",
      追蹤車輛: "追蹤車輛",
      shopify: "收貨鳥",
      // ======= Dashboard ========
      "LDD 路線": "LDD 路線",
      "離線車輛－暫無最近路線紀錄。": "離線車輛－暫無最近路線紀錄。",
      在線: "在線",
      所有車輛: "所有車輛",
      按上線時間: "按上線時間",
      按營運商分類: "按營運商分類",
      按路線分類: "按路線分類",
      按車牌: "按車牌",
      按車速: "按車速",
      搜尋營運商: "搜尋營運商",
      搜尋路線: "搜尋路線",
      搜尋車輛: "搜尋車輛",
      更新時間: "更新時間",
      未有營運商: "未有營運商",
      未有路線: "未有路線",
      離線: "離線",
      顯示所有車輛: "顯示所有車輛",
      // ======= Reports ========
      "行程時間門檻": "行程時間門檻 (分:秒)",
      "C&W": "中西區",
      "Kowloon City": "九龍城區",
      "Kwai Tsing": "葵青區",
      "Kwun Tong": "觀塘區",
      "Order by District": "按地區分類",
      "Please select alarm trigger position": "必須選擇警報觸發位置",
      "Sai Kung": "西貢區",
      "Search District": "搜尋地區",
      "Sham Shui Po": "深水埗區",
      "Shatin/MOS": "沙田區",
      "Tai Po": "大埔區",
      "Tsuen Wan": "荃灣區",
      "Tuen Mun": "屯門區",
      vehicleInsideCluster: "範圍內車輛:",
      "Wong Tai Sin": "黃大仙區",
      "Yau Tsim Mong": "油尖旺區",
      "Yuen Long": "元朗區",
      "user-manual": "用戶手冊",
      "全日/時段": "全日/特定時段",
      "全日/時段itv": "全日/特定時段",
      Eastern: "東區",
      HKI: "香港島",
      Islands: "離島區",
      KLN: "九龍",
      NT: "新界",
      North: "北區",
      South: "南區",
      Wch: "灣仔區",
      acc: "供電",
      all: "全部",
      battery: "電池",
      externalPowerSupply: "外置電源",
      hdop: "hdop",
      inOutRegion: "進入及離開圍欄",
      inRegion: "進入圍欄",
      live_stream: "實時串流",
      outRegion: "離開圍欄",
      reservation_service: "預約服務",
      streaming: "串流影像",
      totalRow: "總紀錄數目",
      密碼到期日: "密碼到期日:",
      小時itv: "小時",
      暫停載客: "暫停載客",
      服務中: "服務中",
      狀態: "狀態",
      班次: "班次",
      車牌: "車牌",
      溫度: "溫度",
      RegNumberAlreadyExists: "已有相同車牌車輛",
      showOfflineVehicles: "顯示離線車輛",
      hideOfflineVehicles: "隱藏離線車輛",
      showTraffic: "顯示交通流量",
      hideTraffic: "隱藏交通流量",
      "doubleClick detail": "雙擊項目:查看詳細",
      // ======== Manage ==========
      "manage-home": "管理主頁",
      "cares-about-your-manage": " 開始管理你的車隊",
      "select-manage-applications": "從左邊選取管理相關應用",
      // ======== AI ========
      "ai-applications-all-in": "車隊 AI 應用，盡在 ",
      "select-ai-applications": "從左邊選取 AI 應用",
      // ======== Schoolbus ========
      "schoolbus-title": "校巴管理系統",
      // ======= Revenue ======
      "cares-about-your-revenue": " 明白您的收益事關重要",
      "select-revenue-applications": "從左邊選取收益相關應用",
      // ======= SOS Noti ======
      "management-sos-noti": "管理 - 求救通知",
      "separate-email-by-comma": "用逗號分隔電子郵件",
      sms: "電話短訊",
      "route-config": "路線設定(新)",
      route_code: "路線編號",
      ocl_route_code: "八達通機路線編號",
      region: "區域",
      district: "地區",
      circular: "循環線",
      route_seq: "路線次序",
      psl_id: "客運營業證編號",
      official_journey_time: "行車時間（分鐘）",
      orig_tc: "起點(繁體)",
      orig_sc: "起點(簡體)",
      orig_en: "起點(英文)",
      dest_tc: "終點(繁體)",
      dest_sc: "終點(簡體)",
      dest_en: "終點(英文)",
      description_tc: "路線說明(繁體)",
      description_sc: "路線說明(簡體)",
      description_en: "路線說明(英文)",
      notification: "通知中心",
      "notification-center": "通知中心",
      "send-notification": "發送通知",
      "english-noti-msg": "通知(英文)",
      "chinese-noti-msg": "通知(中文)",
      send: "發送",
      clear: "清除",
      administrator: "管理員",
      court: "Court",
      site: "Site",
      titleTc: "標題(繁)",
      titleSc: "標題(簡)",
      titleEn: "標題(英)",
      displayType: "首頁顯示方式",
      hideStop: "隱藏車站",
      trafficMessage: "顯示交通信息方式",
      default: "預設",
      showTrafficMessageOnEveryStop: "在每個車站顯示班次延誤訊息",
      displayRoutes: "顯示路線",
      vehicleLocationPublic: "顯示車輛位置",
      routeCodePublic: "顯示路線編號",
      languageBtn: "顯示語言選項按鈕",
      sta: "啟用STA",
      displayVehicleColor: "使用不同車輛顏色",
      displayStopColor: "使用不同車站顏色",
      etaApp: "ETA網上應用程式",
      enable: "啟用",
      disable: "停用",
      routes: "路線",
      delEtaApp: "刪除ETA網上應用程式",
      "eta-app-setting-config": "ETA網上應用程式設定",
      allStops: "全部站點",
      firstStop: "首站",
      headTailStops: "頭尾站",
      tailStops: "尾站",
      noStop: "不顯示站點",
      schedulePreview: "預覽時間表",
      noSchedule: "當日沒有預定班次",
      pleaseSelectWeekday: "請選擇日子",
      notFound: "找不到頁面",
      prevPage: "上一頁",
      setPresetAnnouncement: "設定預設消息",
      contentC: "繁中消息",
      contentE: "英文消息",
      contentS: "簡中消息",
      enabled: "啟用",
      delete: "刪除",
      save: "儲存",
      cancel: "取消",
      add: "新增",
      routeSettingTips:
        "提示: 更新路線後, 必須到「基本資料設定」提交更新, 才能更新Production版本。",
      managingRoutes: "管理路線",
      row: "行",
      column: "列",
      etaDisplayMethod: "顯示ETA方式",
      "kmb-demo-msg": "如直播停止,請檢查網絡連線並嘗試重新整理頁面",
      Granularity: "精度",
      秒: "秒",
      分鐘: "分鐘",
      小時: "小時",
      unlimited: "無限制",
      Recent: "最近",
      "Total In/Out": "總出入",
      "pcs-detail": "客流詳情",
      In: "入",
      Out: "出",
      Net: "淨出入",
      Front: "前門",
      Back: "後門",
      Total: "合共",
      Order: "順序",
      Time: "時間",
      "webappDomainInstruction": "請設置閣下DNS伺服器相應子域名紀錄為"
    },
  },
  en: {
    translation: {
      // ======== General ========
      名稱: "Name",
      啟用: "Enable",
      車輛車牌: "Plate",
      新增: "Add",
      營運商: "Operator",
      簡體中文: "Simplified Chinese",
      繁體中文: "Traditional Chinese",
      英文: "English",
      路線: "Route",
      車輛: "Vehicle",
      載入中: "Loading",
      間隔: "Interval",
      // ======= LOGIN =======
      密碼: "Password",
      專線車輛實時到站系統網上管理平台:
        "Green Minibus Estimated Time of Arrival  - Online Management Platform",
      用戶名稱: "Username",
      用戶名稱或密碼錯誤: "Username or Password is wrong",
      登入: "Login",
      請輸入用戶名稱及密碼: "Please Input Username and Password",
      // ======= MENU =======
      "add-route": "Add Route",
      "arrival-timetable": "Arrival Timetable",
      "card-reader": "Card Reader Config",
      "court-home": "Current Map",
      "departure-arrival-report": "Departure Report",
      "device-config": "Device Config",
      "driver-card": "Driver Card Config",
      "driver-log-report": "Driver Attendance Report",
      "exception-reports": "Exception Reports",
      "exception-summary-report": "Exception Summary Report",
      "ffnd-report": "Full Fleet No Data Report",
      "geo-fencing": "Geo-fencing",
      "vehicle-config": "Vehicle Config",
      "group-tag": "Vehicle Tag",
      "ignition-report": "Ignition Report",
      "journey-time-exception-report": "Journey Time Exception Report",
      "journey-time-report": "Journey Time Report",
      "live-stream": "Live Stream",
      "miles-report": "Miles Report",
      "offline-report": "Offline Report",
      "oor-day-report": "Out of Operating Days Report",
      "oor-hour-report": "Out of Operating Hours Report",
      "operating-report": "Operation Reports",
      "operation-summary-report": "Operation Summary",
      "operator-config": "Operator Config",
      "out-of-route-report": "Out-of-Route Report",
      "route-management": "Route Config",
      "rsl-report": "Remain at the Same Location Report",
      "schedule-config": "Schedule Config",
      "speeding-report": "Speeding Report (for reference only)",
      "sos-report": "Sos Report",
      "sos-config": "Sos Config",
      "system-exception-report": "System Exception Report",
      "temp-disable-eta": "Toggle ETA",
      "trip-record": "Trip Records",
      "trip-replay": "Trip Replay",
      "trip-viewer": "History Trips",
      "user-config": "User Config",
      "vehicle-allocation-report": "Vehicle Allocation Report",
      "web-app-config": "Web App Config",
      "revenue-home": "Revenue Home",
      "ai-home": "AI Home",
      "schoolbus-home": "School Bus Home",
      "schoolbus-data": "Data Management",
      "schoolbus-import": "Data Import",
      "schoolbus-card": "Card",
      "schoolbus-student-nanny-driver": "Student/Nanny/Driver",
      "pcs-trip": "PCS Trip Viewer",
      "passenger-flow": "Passenger Flow Statistics",
      "pcs-dashboard": "Passenger Flow Real Time",
      "pcs-graph": "Passenger Flow Graph",
      "kmb-demo": "Bus Real-time Demo",
      "stream-playback": "Stream Playback",
      "shopify-customize": "Shopify Webapp Settings",
      playlist: "Playlist",
      "start-time": "Start Time",
      "end-time": "End Time",
      "now-playing": "Now Playing",
      "no-video": "No Playback",
      autoplay: "Autoplay",
      driver: "Driver Config",
      earn: "Earn",
      logout: "Logout",
      management: "Management",
      reserve: "Reserve",
      專線車輛實時到站系統: "360 ETA",
      網上管理平台: "Online Management Platform",
      網上管理平台Short: "OMP - 360 ETA",
      裝置狀態: "Device Status",
      登出: "Logout",
      // Dashboard
      bookingRoute: "Booking Route",
      "LDD 路線": "LDD Route",
      HKI: "HKI",
      KLN: "KLN",
      NT: "NT",
      在線: "Online",
      地圖由地政總署提供: "The map is provided by Lands Department",
      所有車輛: "All Vehicles",
      按上線時間: "Online Time",
      按營運商分類: "By Operator (PSL)",
      按路線分類: "By Route",
      按車牌: "Plate",
      按車速: "Speed",
      搜尋營運商: "Search Operator/PSL",
      搜尋路線: "Search Route",
      搜尋車輛: "Search Vehicles",
      暫停載客: "Out of Service",
      更新時間: "Last Update Time",
      服務中: "In Service",
      未有營運商: "No Operator",
      未有路線: "No Route",
      正在追蹤: "Tracking",
      狀態: "Status",
      退出: "Exit",
      離線: "Offline",
      顯示所有車輛: "Display All Vehicle",
      溫度: "Temperature",
      // ===== Reports ======
      "行程時間門檻 (%)": "Journey Time Threshold (%)",
      "行程時間門檻 (m:s)": "Journey Time Threshold (m:s)",
      "行程時間門檻": "Journey Time Threshold (m:s)",
      "% (如超過20%)": "% (reported if >20%)",
      "*必填": "*Required",
      "1分鐘": "1min",
      "30秒": "30s",
      "3G/4G": "Network",
      "5分鐘": "5mins",
      "5秒": "5s",
      vehicleInsideCluster: "Vehicle Inside Cluster:",
      "C&W": "Central and Western",
      "Kowloon City": "Kowloon City",
      "Kwai Tsing": "Kwai Tsing",
      "Kwun Tong": "Kwun Tong",
      "Order by District": "Order by District",
      "Production 版本": "Production",
      "Sai Kung": "Sai Kung",
      "Search District": "Search District",
      "Sham Shui Po": "Sham Shui Po",
      "Shatin/MOS": "Sha Tin",
      "Staging（永久）版本": "Staging (Permanent)",
      "Staging（臨時）版本": "Staging (Temporary)",
      "Tai Po": "Tai Po",
      "Tsuen Wan": "Tsuen Wan",
      "Tuen Mun": "Tuen Mun",
      "Wong Tai Sin": "Wong Tai Sin",
      "Yau Tsim Mong": "Yau Tsim Mong",
      "Yuen Long": "Yuen Long",
      "user-manual": "User Manual",
      "上午繁忙時段 (上午7時-9時)": "Morning Peak (7-9AM)",
      "中午繁忙時段 (上午9時-下午5時)": "Inter-Peak (9AM - 5PM)",
      "中途站班次報表(全日/特定時段摘要)":
        "En-route stop Departure Report (Daily/Specific Time Period Summary)",
      "中途站班次報表(小時摘要)":
        "En-Route Stop Departure Report (Hourly Summary)",
      "中途站班次報表(所有班次摘要)":
        "En-route stop Departure Report (Every Departure Summary)",
      "估計平均速度 (公里/小時)": "Estimated Average Speed (km/h)",
      "位置(經緯)": "Position (Lat,Lng)",
      "全日/ 特定時段": "Daily / Time Period",
      "全日/時段": "Daily/Period",
      "全日/時段itv": "Daily/Period",
      "在線/離線": "Online / Offline",
      "密碼過期，請聯絡系統管理員重設密碼":
        "Password is expired, please contact system administrator to reset password.",
      "實際平均班距 (分鐘)": "Actual Average Headway (min)",
      "實際最短班距 (分鐘)": "Actual Min. Headway (min)",
      "實際最長班距 (分鐘)": "Actual Max. Headway (min)",
      "車輛記錄 - 行程追溯": "Trip Records - History Trips ",
      "車輛記錄 - 行車軌跡": "Trip Records - Trip Replay",
      "差距 (公里/小時)": "Difference (km/h)",
      "成功更新班次。": "Successfully Update Schedule",
      "成功更新路線。即將重新載入此頁面。":
        "Successfully Update Route. Reloading the page",
      "搜尋無結果，顯示所有資料": "No result, display all data",
      "搜尋營運商名稱(繁)": "Search by Op. Name (TC)",
      "搜尋營運商名稱(英)": "Search by Op. Name (EN)",
      "時間(小時)": "Time (Hour)",
      "有關路段的車限速上限 (公里/小時)":
        "Speed Limit of the road section concerned (km/h)",
      "服務詳情表編定行車時間 (分鐘)": "Journey Time in SoS (Min)",
      "未能成功更新路線, 請稍後再試。":
        "Unable to update route, please try again later.",
      "未能更新營運商，請確保客運營業證未被其他營運商使用":
        "Unable to update operator, please make sure the psl is unique.",
      "未能更新班次,請稍後再試。": "Cannot Update Schedule, please try again.",
      "沒有搜尋結果，請重試。": "No searching result, please retry.",
      "沒有相關數據。": "No related data。",
      "營運商(唯讀)": "Operator (Read only)",
      "營運商名稱(簡)": "Operaotr Name (SC)",
      "營運商名稱(繁)": "Operator Name (TC)",
      "營運商名稱(英)": "Operator Name (EN)",
      "班距(分鐘)": "Headway (min)",
      "班距上限(分鐘)": "Max. Headway (min)",
      "管理 - 車輛": "Vehicle Management",
      "管理 - 用戶": "User Management",
      "管理 - 裝置": "Device Management",
      "終點(簡體)": "Destination (EN)",
      "終點(繁體)": "Destination (TC)",
      "終點(英文)": "Destination (SC)",
      "編定班距 (分鐘)": "Scheduled Headway (min)",
      "總站班次報表(全日/特定時段摘要)":
        "Terminus Departure Report (Daily/Specific Time Period Summary)",
      "總站班次報表(小時摘要)": "Terminus Departure Report (Hourly Summary)",
      "總站班次報表(所有班次摘要)":
        "Terminus Departure Report (Every Departure Summary)",
      "行車時間報表(全日/特定時段摘要)":
        "Journey Time Report (Daily/ Specific Time Period Summary)",
      "行車時間報表(所有班次摘要)":
        "Journey Time Report (Every Departure Summary)",
      "行車時間表(全日/特定時段摘要)":
        "Journey Time Report (Daily / Specific Time Period Summary)",
      "行車時間（分鐘）": "Journey Time (min)",
      "請填寫路線。": "Please select Route.",
      "請選擇日期。": "Please select Date.",
      "請選擇營運商/路線/車站。": "Please select Operator & Route & Station.",
      "請選擇營運商。": "Please select operator.",
      "請選擇營運商／車牌號碼。": "Please select Operator / Plate.",
      "請選擇路線/方向/日期及間隔。":
        "Please Select Operator/ Route/ Direction and Date.。",
      "起點(簡體)": "Origin (SC)",
      "起點(繁體)": "Origin (TC)",
      "起點(英文)": "Origin (EN)",
      "路線說明(簡體)": "Route Description (EN)",
      "路線說明(繁體)": "Route Description (TC)",
      "路線說明(英文)": "Route Description (SC)",
      "車站名(中簡)": "Station Name (SC)",
      "車站名(中繁)": "Station Name (TC)",
      "車站名(英文)": "Station Name (EN)",
      "車輛編配報表(小時摘要)": "Vehicle Allocation Report (Hourly Summary)",
      "車輛編配報表(每日/特定時段摘要)":
        "Vehicle Allocation Report (Daily/ Specific Time Period Summary)",
      "車輛編配數目 (服務詳情表)": "Vehicle Allocation (SoS)",
      "運行車輛數目(服務詳情表車輛編配數目)":
        "No. of Vehicles in Operation (# Vehicle allocation in SoS)",
      "運輸署(唯讀)": "Transport Department (Read only)",
      "運輸署(路線唯讀)": "Transport Department (Read only - Route)",
      "非繁忙時段 (其餘時間)": "Off Peak(the rest)",
      "黃昏繁忙時段 (下午5時-7時)": "Evening Peak (5-7 PM)",
      '用";"分隔多個資料(如有)': 'Use ";" to separate mulitple data',
      Eastern: "Eastern",
      GPS: "GPS Comm",
      Islands: "Islands",
      North: "North",
      PD: "PD Comm",
      South: "Southern",
      T1出發數目: "No. of Vehicles in Operation (# Vehicle Allocation in SoS) (T1)",
      T1總站名稱: "Terminal Name (T1)",
      T2出發數目: "No. of Vehicles in Operation (# Vehicle Allocation in SoS) (T2)",
      T2總站名稱: "Terminal Name (T2)",
      Wch: "Wan Chai",
      all: "all",
      inOutRegion: "Enter and Exit Geofence",
      inRegion: "Enter Geofence",
      live_stream: "Live Streaming",
      outRegion: "Exit Geofence",
      reservation_service: "Reservation Service",
      streaming: "Streaming Video",
      totalRow: "Total Record Count:",
      一: "M",
      三: "W",
      上一頁: "Previous",
      上個月: "Last Month",
      上移: "Move Upward",
      下一頁: "Next",
      下移: "Move Downward",
      不能更改營運商管理用戶之權限: "Cannot change Operator Admin's Permission",
      不適用: "N/A",
      不選擇為全選: "Default: all",
      中西區: "Central and Western",
      九龍城區: "Kowloon City",
      二: "Tu",
      五: "F",
      今個月: "This Month",
      今天: "Today",
      位置: "Coordinates",
      偏離路線: "Out-of-route",
      偏離路線時間: "OOR Duration",
      偏離路線次數: "Number of Times a Vehicle Goes Out of Route",
      偏離路線結束地點: "OOR End Location",
      偏離路線結束時間: "OOR End Time",
      偏離路線開始地點: "OOR Start Location",
      偏離路線開始時間: "OOR Start Time ",
      儲存: "Save",
      元朗區: "Yuen Long",
      全日: "Daily",
      全日全車隊未能接收數據: "Full fleet no data for whole day",
      全選: "All",
      全部: "All",
      全部匯出: "Export All",
      全部展開: "Expand all",
      全部收起: "Collaspe all",
      全部營運商: "All Operators",
      八達通機路線編號: "LDD Route Code",
      公眾假期: "Public Holiday",
      公里: "KM",
      六: "Sa",
      共: "Total",
      共: "Total",
      再次輸入新密碼: "Input new password again",
      出勤中: "In Service",
      出發班次相差: "Difference in No. of Departures ",
      分: "m",
      分區: "District",
      分鐘: " mins",
      刪除: "Delete",
      刪除路線: "Delete Route",
      切換語言: "Switch language",
      到: "To",
      到期日: "Expiry Date",
      到期日必須在啟用日後: "Expiry Date should be after Effective Date",
      到站點: "To Station",
      北區: "North",
      匯出: "Export",
      區域: "Region",
      南區: "Southern",
      去: "To",
      取消: "Cancel",
      只支援不多於七天的時間範圍:
        "Only support not more than 7 days time range",
      只顯示已完成的行程: "Only show completed trips",
      否: "No",
      啟用: "Enable",
      啟用日: "Effective Date",
      四: "Th",
      圍欄名稱: "Geo-Fencing Name",
      在正常營運日子以外運作的結束位置: "End Location of OOD",
      在正常營運日子以外運作的開始位置: "Start Location of OOD",
      在營運日子以外運作的結束日子: "End Time of OOD",
      在編定營運日子以外運作多於一分鐘的開始時間:
        "Start Time when OOD was recorded for more than 1 min",
      地區: "District",
      地點: "Location",
      基本資料設定: "Basic Info Config",
      大埔區: "Tai Po",
      完成時間: "End Time",
      完結時間: "End Time",
      客運營業證: "PSL",
      客運營業證編號: "PSL",
      密碼: "Password",
      密碼不相同: "Passowrd is not matched",
      密碼到期日: "Password Expiry Date:",
      實際出發時間: "Actual Depature Time",
      實際出發班次: "Actual No. of Departures",
      實際平均班距: "Actual Average Headway",
      實際最短班距: "Actual Min. Headway",
      實際最長班距: "Actual Max. Headway",
      實際班距: "Actual Headway",
      實際行程時間: "Actual Journey Time (Min)",
      小時: "h",
      小時itv: "Hourly",
      尾班車出發時間: "Last Departure Time",
      尾班車時間: "Last Departure Time",
      屯門區: "Tuen Mun",
      差距: "Difference",
      帳戶資料: "Account Information",
      平均: "Average",
      待命中: "Out of Service",
      從站點: "From Station",
      循環線: "Circular",
      循環線站點編號: "Circular Stop No.",
      循環線站點編號只可以包含數字:
        "Circular Stop No. should include number ONLY",
      必填: "Required",
      必需填寫: "Required",
      必須填寫八達通機路線編號: "LDD Route Code is required",
      必須填寫客運營業證編號: "PSL is required",
      必須填寫時間: "Time is Required",
      必須填寫班次設定: "Schedule is required",
      必須填寫終點名稱: "Destination Name is required",
      必須填寫起點名稱: "Origin Name is required",
      必須填寫路線編號: "Route Code is required",
      必須填寫頭班車時間: "First Departure Time is required",
      必須畫出電子圍欄: "Must draw the geo-fence",
      恢復班次: "Resume Schedule",
      成功刪除電子圍欄: "Successfully delete geo-fence",
      成功新增用戶: "Successfully create user",
      成功新增電子圍欄: "Successfully add geo-fence",
      成功更新營運商: "Successfully update operator",
      成功更新用戶: "Successfully update user",
      成功更新電子圍欄: "Successfully update geo-fence",
      所有班次: "Every Departure",
      排序: "Sequence",
      提交: "Submit",
      搜尋: "Search",
      搜尋IMEI: "Search by IMEI",
      搜尋客運營業證: "Search by PSL",
      搜尋車牌: "Search by Plate",
      新增: "Add",
      新增車輛: "Add Vehicle",
      新增營運商: "Add Operator",
      新增班次: "Add Schedule",
      新增用戶: "Add User",
      新增裝置: "Add Device",
      新增電子圍欄: "Add Geo-Fence",
      新車站: "New Stop",
      方向: "Direction",
      日: "Su",
      日期: "Date",
      星期一: "Mon",
      星期三: "Wed",
      星期二: "Tue",
      星期五: "Fri",
      星期六: "Sat",
      星期四: "Thur",
      星期日: "Sun",
      昨天: "Yesterday",
      是: "Yes",
      是否循環線: "Circular Route?",
      時段: "Time Period",
      時間: "Time",
      時間不能早於: "Time cannot set before ",
      時間不能超過: "Time cannot set after ",
      時間範圍錯誤: "Wrong time range",
      時間間隔: "Interval",
      暫無: "N/A",
      更改: "Update",
      更改密碼: "Update",
      更新密碼: "Update password",
      更新車輛: "Update Vehicle",
      最少填寫一項: "Required at least one filter",
      最後更新時間: "Last Update Time",
      最後行車紀錄時間: "Last Record Time",
      最快: "Min",
      最慢: "Max",
      未完成: "Incomplete",
      未有: "N/A",
      未能成功刪除電子圍欄: "Cannot delete geo-fence",
      未能成功更新電子圍欄: "Cannot update geo-fence",
      未能找到相關數據: "No Results, please try again",
      未設定: "N/A",
      東區: "Eastern",
      條行程: "trips",
      權限: "Permission",
      次序: "Order",
      正常: "Normal",
      每頁數量: "Trips per page",
      沒有資料: "No Data",
      沒有電子圍欄: "No geo-fence",
      沙田區: "Sha Tin",
      油尖旺區: "Yau Tsim Mong",
      深水埗區: "Sham Shui Po",
      清除: "Clear",
      灣仔區: "Wan Chai",
      無路線: "No Route",
      營業日子異常次數: "Number of Times a Vehicle Operates Out of Operating Days",
      營業時間異常次數: "Number of Times a Vehicle Operates Out of Operating Hours",
      營運商名稱: "Operator Name",
      班次: "Schedule",
      班次數目: "No. of Arrival",
      班次編號: "Departure No.",
      班距上限必須大於班距: "Max. Headway must be greater than Headway",
      班距上限必須為大於零的數字:
        "Max. Headway must be a number greater than 0",
      班距必須為大於零的數字: "Headway must be a number greater than 0",
      現在路線: "Current Route",
      用戶: "User",
      用戶名稱: "Username",
      用戶帳號: "Username",
      由: "From",
      畫出進入範圍: "Draw Entry Area",
      當時路線: "Route (if exist)",
      真實到站時間: "Actual Arrival Time",
      確定: "Confirm",
      禁用: "Disable",
      秒: "s",
      站名: "Station Name",
      站數: "Station #",
      站點: "Station",
      第: "Page",
      第一班: "First",
      第三班: "Third",
      第二班: "Second",
      範圍: "Area",
      範圍內: "In Range",
      範圍外: "Out of Range",
      系統會自動產生: "System will auto generate KEY",
      結束地點: "End Location",
      結束時間: "End Time",
      線主: "Subject Officer",
      編定出發時間: "Scheduled Departure Time",
      編定出發班次: "Scheduled No. of Departures",
      編定營運日子: "Scheduled Operating Days",
      編定班次: "Scheduled",
      編定班距: "Scheduled Headway",
      編號: "ID",
      編輯: "Edit",
      緩衝: "Buffer",
      總出勤時間: "Total Journey Time",
      總站: "Terminus",
      總行程時間: "Total Trip Time",
      總行車里數: "Total Miles",
      總行車里數: "Total Miles",
      總行駛里數: "Total Miles",
      總轉數: "Total Trips",
      聯絡人: "Contact Person",
      舊密碼錯誤: "Old password is wrong",
      荃灣區: "Tsuen Wan",
      葵青區: "Kwai Tsing",
      行程: "Trips",
      行程結束時間: "Trip End Time",
      行程開始時間: "Trip Start Time",
      行車受阻: "Delayed",
      行車時間: "Travelled Time",
      行車時間數據: "Journey Time Statistics",
      行駛里數: "Miles",
      裝置: "Device",
      裝置IMEI: "Device IMEI",
      裝置KEY: "Device KEY",
      西貢區: "Sai Kung",
      觀塘區: "Kwun Tong",
      記憶體: "LDD",
      詳細: "Details",
      請在地圖上繪製電子圍欄: "Please draw the geo-fence on the map.",
      請選擇: "Please Select",
      請選擇其他路線編號: "Please select other Route Code",
      警報觸發位置: "Alarm Tigger Position",
      距離: "Distance",
      路段: "Street",
      路線已被註冊: "Route Code is used",
      路線方向: "Direction",
      路線次序: "Route Sequence",
      路線編號: "Route Code",
      路線編號只可以包含數字及英文字母:
        "Route Code should include number and letter ONLY",
      路線號碼: "Route Code",
      車牌: "Liscense Plate",
      車牌: "Plate",
      車站: "Station",
      車站位置: "Station Location",
      車站編號: "Station #",
      車資設定: "Fare Config",
      車輛編配: "Vehicle Allocation",
      車隊全部車輛多於一小時未能接收數據報表:
        "Full Fleet No Data for More Than One Hour Report",
      車隊全部車輛多於一小時無資料次數:
        "Number of Times that No Data can be Received for Full Fleet for more than 1 Hour",
      輸入新密碼: "Input new password",
      輸入舊密碼: "Input old password",
      速度: "Speed",
      速度變化: "Change of speed",
      運行車輛停留同一位置多於: "Remain at Same Location for more than",
      運行車輛停留同一位置多於一小時數目:
        "Number of Vehicles in Operation but Remain at Same Location for more than 1 Hour",
      運行車輛數目: "# No. of Vehicles in Operation",
      運輸署: "Administrator",
      過去10分鐘: "past 10 mins",
      過去1小時: "past 1 hour",
      過去30分鐘: "past 30 mins",
      適用日子: "Day of the week",
      選擇營運商: "Select Operator",
      選擇路線: "Select Route",
      選擇路線及停車站: "Select Route and Stop",
      部車輛: "Vehicles",
      重複密碼: "Re-enter Password",
      重設: "Reset",
      錯誤日期格式: "Wrong Date Format",
      鐘: "",
      開始地點: "Start Location",
      開始時間: "Start Time",
      開始繪畫範圍: "Start Drawing Area",
      關閉: "Close",
      隱藏在線車輛: "Hide Online Vehicles",
      隱藏班次: "Hide Schedule",
      隱藏禁用營運商: "Hide Disabled Operators",
      hideOfflineVehicles: "Hide Offline Vehicles",
      離島區: "Islands",
      電話: "Tel",
      電郵: "Email",
      頁: "",
      頁面: "Pages",
      順序: "Sequence",
      頭班車時間: "First Departure Time",
      顯示在線車輛: "Show Online Vehicles",
      顯示禁用營運商: "Show Disabled Operators",
      showOfflineVehicles: "Show Offline Vehicles",
      showTraffic: "Show Traffic",
      hideTraffic: "Hide Traffic",
      首班車出發時間: "First Departure Time",
      首站: "First Station",
      黃大仙區: "Wong Tai Sin",
      ai: "AI",
      司機: "Driver",
      讀卡器: "Card Reader",
      證件: "Card",
      司機拍卡報表: "Driver Log Report",
      開車報表: "Ignition Report",
      熄車報表: "Stop Engine Report",
      車輛於時間範圍內的熄匙及撻匙時間:
        "The Ignition and Engine Off time between time range",
      "車輛於時間範圍內的開/熄車時間":
        "The Stop Engine time between time range",
      停車判斷時間: "Identification Time",
      "15分鐘": "15 Minutes",
      "30分鐘": "30 Minutes",
      "1小時": "1 Hour",
      "2小時": "2 Hours",
      "4小時": "4 Hours",
      "8小時": "8 Hours",
      秒: "seconds",
      分鐘: "minute(s)",
      小時: "hour(s)",
      停車時間: "Stop Time",
      開車時間: "Start Time",
      相差: "Different",
      求救報表: "SOS Report",
      "車輛於時間範圍內的求救紀錄 (只適用於有求救按鈕的裝置)":
        "Sos Record in time range",
      裝置編號: "Tracker Code",
      電子圍欄: "Geo-Fencing",
      路線設定: "Route Setting",
      新增路線: "Add Route",
      營運商設定: "Operator Config",
      班次設定: "Schedule Config",
      "管理 - 讀卡器": "Card Reader Config",
      "管理 - 司機": "Driver Config",
      "管理 - 證件": "Card Config",
      "管理 - 車輛標籤": "Vehicle Group Config",
      發佈消息: "Web App Config",
      設定日期: "Date",
      上傳車輛分配: "Upload Vehicle Allocation",
      上傳失敗: "Upload Failed",
      未編配: "Not Assigned",
      管理: "Config",
      刪除讀卡器: "Delete Card Reader",
      刪除司機: "Delete Driver",
      卡面編號: "Card Number",
      刪除證件: "Delete Card",
      到達時間: "Arrival Time",
      負責車輛: "Vehicle",
      更改後: "After update",
      新增預約: "New Booking",
      圖像: "Graph",
      表格: "Table",
      "doubleClick detail": "Double Click: Check Details",
      確認更改預訂單: "Confirm update booking",
      確認取消預訂單: "Cancel booking",
      單號: "Booking ID",
      更改前: "Before update",
      未分配: "Not Allocated",
      "請選擇起點、目的地及負責車輛":
        "Please input departure & destination location and vehicle",
      預計行程距離: "Estimated Distance",
      "請選擇起點及目的地。": "Select departure & destination location",
      預計到達時間: "Estaimted Time of Arrival",
      選擇: "Select",
      上車地點: "Departure Location",
      預約時間: "Booking Time",
      目的地: "Destination Location",
      行程時間: "Journey Time",
      分鐘: "Minutes",
      預訂單號: "Booking Id",
      預訂單: "Booking Id",
      運行時間: "Travelled Time",
      總上車人次: "Total Aboard Count",
      總下車人次: "Total Alight Count",
      現時乘客數量: "Current Passenager No.",
      實時串流: "Realtime Streaming",
      觀看: "View",
      查看: "Detail",
      總上落人次: "Total Count",
      總滿載率: "Rate of Full Vehicle",
      行駛中的車輛: "Vehicle in Trip",
      上車人次: "Aboard Count",
      "(上)": "(Aboard)",
      "(落)": "(Alight)",
      "實時 AI 串流": "Realtime AI Streaming",
      總運行時間: "Total Travelled Time",
      "請輸入中文消息、英文消息及過期時間。":
        "Please input Chinese & English Message and expiry time",
      發佈消息: "Public Message",
      發佈頁面: "Publish Page",
      預設消息: "Default Message",
      "消息內容（繁中）": "Message Content(Traditional Chinese)",
      "消息內容（英文）": "Message Content(English)",
      "消息內容（簡中）": "Message Content(Simplified Chinese)",
      "過期時間：": "Expiry Time",
      應用程式會顯示: "Display in Webapp as",
      過期時間: "Expiry Time",
      動作: "Action",
      車輛標籤: "Group Tag",
      成功更改標籤: "Successfully Update Tag",
      成功刪除標籤: "Successfully Delete Tag",
      標籤: "Tag",
      刪除標籤: "Delete Tag",
      離線報表: "Offline Report",
      追蹤車輛: "Track Vehicle",
      // ======== Manage ==========
      "cares-about-your-manage": "Start manage your fleet",
      "select-manage-applications":
        "Select Manage Application on the left menu",
      "manage-home": "Manage Home",
      // ======== AI ========
      "ai-applications-all-in": "Fleet AI Applications, All in ",
      "select-ai-applications": "Select AI Application on the left menu",
      // ======= Revenue ======
      "cares-about-your-revenue": " cares about the revenue of your fleet",
      "select-revenue-applications":
        "Select Revenue related Application on the left menu",
      // ======= SOS Noti ======
      "management-sos-noti": "Management - Sos Notification",
      "separate-email-by-comma": "Separate multiple emails by comma",
      sms: "SMS",
      "route-config": "Route Config (New)",
      route_code: "Route Code",
      ocl_route_code: "OCL Route Code",
      region: "Region",
      district: "District",
      circular: "Circular Route",
      route_seq: "Route Sequence",
      psl_id: "PSL Id",
      official_journey_time: "Official Journey Time (Min)",
      orig_tc: "Origin(TC)",
      orig_sc: "Origin(SC)",
      orig_en: "Origin(EN)",
      dest_tc: "Destination(TC)",
      dest_sc: "Destination(SC)",
      dest_en: "Destination(EN)",
      description_tc: "Route Description(TC)",
      description_sc: "Route Description(SC)",
      description_en: "Route Description(EN)",
      notification: "Notification Center",
      "notification-center": "Notification Center",
      court: "Court",
      site: "Site",
      titleTc: "Title (TC)",
      titleSc: "Title (SC)",
      titleEn: "Title (EN)",
      displayType: "Homepage Display Method",
      hideStop: "Hide Stop",
      trafficMessage: "Traffic Message Display Method",
      default: "Default",
      showTrafficMessageOnEveryStop: "Show Traffic Message on Every Stop",
      displayRoutes: "Display Routes",
      displayVehicleColor: "Display Vehicle Color",
      displayStopColor: "Display Stop Color",
      vehicleLocationPublic: "Display Vehicle Location",
      routeCodePublic: "Display Route Code",
      languageBtn: "Display Language Button",
      sta: "Enable STA",
      etaApp: "ETA Web App",
      enable: "Enable",
      disable: "Disable",
      routes: "Routes",
      delEtaApp: "Delete ETA Web App",
      "eta-app-setting-config": "ETA Web App Config",
      allStops: "All Stops",
      firstStop: "First Stop",
      headTailStops: "First and Last Stops",
      tailStops: "Tail Stop",
      noStop: "No Stop",
      schedulePreview: "Schedule Preview",
      noSchedule: "No Schedule for the day",
      pleaseSelectWeekday: "Please select weekday",
      notFound: "Page Not Found",
      prevPage: "Previous Page",
      setPresetAnnouncement: "Setup Preset Announcement",
      contentC: "TC Message",
      contentE: "Eng Message",
      contentS: "SC Message",
      enabled: "Enable",
      delete: "Delete",
      save: "Save",
      cancel: "Cancel",
      add: "Add",
      routeSettingTips:
        "Tips: In order to updating the route after editing, please go to 'basic information' and sumbit update once.",
      managingRoutes: "Managing Routes",
      row: "Row",
      column: "Column",
      etaDisplayMethod: "ETA Display Method",
      "kmb-demo-msg":
        "If the live stream stops, please check your Internet connection and try to refresh the page",
      Granularity: "Granularity",
      unlimited: "Unlimited",
      Recent: "Recent",
      "Total In/Out": "Total In/Out",
      "pcs-detail": "PCS Detail",
      In: "In",
      Out: "Out",
      Net: "Net",
      Total: "Total",
      Front: "Front",
      Back: "Back",
      Order: "Order",
      Time: "Time",
      正在加載客流數據: "Loading PCS Data",
      shopify: "PickupBird",
      送貨地址: "Delivery Address",
      送貨時間: "Delivery Time",
      聯絡電話: "Contact Phone",
      備註: "Note",
      排單: "Schedule",
      設定: "Settings",
      已排程: "Scheduled",
      未排程: "Unscheduled",
      編配全日時段: "Arrange Whole-day session",
      最早送達時間: "Earliest delivery time",
      最遲送達時間: "Latest delivery time",
      短訊通知客人: "Notify customer by SMS",
      追蹤連結: "Tracking URL",
      商標: "Logo",
      色調: "Colour",
      "自訂客戶端Web App域名": "Customize customer-side Web App Domain",
      "webappDomainInstruction": "Please set your respective DNS record to",
      安排時段: "Arrange Timeslot",
      司機已開始行程: "Driver has started the trip",
      "司機未開始行程，尚可更改訂單": "Driver has not started the trip yet, arrangement can be modified",
      取消排單: "Cancel arrangement",
      "確認取消訂單時段及司機分配": "Cancel arranged timeslot and driver assignment?"
    },
  },
};

const locale = localStorage.getItem("lang");

i18n.locale = locale;

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    // lng: 'en',
    lng: locale || "zh",
    fallbackLng: "zh",
    saveMissing: true,

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
