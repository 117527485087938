import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { Redirect, Route } from "react-router-dom";
import { AuthContext } from "../../App";

const ProtectedRoute = ({
  component: Component,
  pagePermission,
  section,
  config,
  ...rest
}) => {
  const { state } = useContext(AuthContext);
  const [permissionGranted, setPermissionGranted] = useState(null);
  useEffect(() => {
    let permissions = [];
    if (state.permissions.length > 0) {
      permissions = state.permissions;
    } else if (localStorage.getItem("permissions")) {
      permissions = localStorage.getItem("permissions").split(",");
    }
    setPermissionGranted(permissions.includes(pagePermission));
  }, [pagePermission]);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (permissionGranted !== null) {
          if (
            (state.userId || localStorage.getItem("userId")) &&
            moment(moment(localStorage.getItem("pwExpiry"))).diff(Date.now()) >
              0
          ) {
            if (permissionGranted) {
              return (
                <Component
                  {...rest}
                  section={section || props.match.params.section}
                  config={config}
                />
              );
            }
            return (
              <Redirect
                to={{
                  pathname: "/forbidden",
                }}
              />
            );
          }
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
};

export default ProtectedRoute;
