import { constructParams } from "../utils/data";

export const fetchUsers = async (userId = null) => {
  const result = await (
    await fetch(`/api/user${userId ? `/${userId}` : ""}`)
  ).json();
  return result?.data;
};

export const fetchUsersOfOperator = async (operatorId) => {
  const result = await (await fetch(`/api/user/${operatorId}`)).json();
  return result?.data;
};

export const addUser = async (user) => {
  const response = await fetch(`/api/user`, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: JSON.stringify(user),
  });
  if (response) {
    const result = await response.json();
    return result.data;
  }
  return [];
};

export const updateUser = async (user) => {
  const response = await fetch(`/api/user`, {
    method: "PUT", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: JSON.stringify(user),
  });
  if (response) {
    const result = await response.json();
    return result.data;
  }
  return [];
};
