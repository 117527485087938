/* eslint-disable no-nested-ternary */
import * as turf from "@turf/turf";
import { distance, point } from "@turf/turf";
import moment from "moment";
import { orderBy } from "lodash";
import Constants from "./constants";
import i18n from "i18next";

const { API_KEY, TIMEZONE, OMP_360_API_URL } = Constants;

export const constructParams = (params) => {
  let str = "";
  if (params) {
    str = `?${new URLSearchParams(params).toString()}`;
  }
  return str;
};

export const getVehicles = async (filter) => {
  const response = await fetch(`/api/getCars${constructParams(filter)}`);
  if (response) {
    const result = await response.json();
    return result;
  }
  return [];
};

export const getCourts = async (filter) => {
  const response = await fetch(`/api/getCourts${constructParams(filter)}`);
  if (response) {
    const result = await response.json();
    return result;
  }
  return [];
};

export const getOrigins = async (filter) => {
  const response = await fetch(`/api/getOrigins${constructParams(filter)}`);
  if (response) {
    const result = await response.json();
    return result;
  }
  return [];
};

export const getRoutes = async (filter) => {
  const response = await fetch(`/api/getFullRoutes${constructParams(filter)}`);
  if (response) {
    const result = await response.json();
    return result;
  }
  return [];
};

export const getHiResRoute = async (filter) => {
  const response = await fetch(`/api/getHiResRoute${constructParams(filter)}`);
  if (response) {
    const result = await response.json();
    return result;
  }
  return null;
};

export const getTrips = async (filter) => {
  const newFilter = {
    ...filter,
    deleted: "false",
  };
  const response = await fetch(`/api/getTrips${constructParams(newFilter)}`);
  if (response) {
    const result = await response.json();
    return result;
  }
  return [];
};

export const getLogs = async (filter) => {
  const response = await fetch(`/api/getLogs${constructParams(filter)}`);
  if (response) {
    const result = await response.json();
    return result;
  }
  return [];
};

export const getOperators = async (filter) => {
  const response = await fetch(`/api/getOperators${constructParams(filter)}`);
  if (response) {
    const result = await response.json();
    return result;
  }
  return [];
};

export const checkCourtNameDuplication = async (
  nameEn,
  nameZh,
  nameCn,
  abbv
) => {
  const res = await fetch(
    `/api/checkNameDuplication?type=court&nameEn=${nameEn}&nameZh=${nameZh}&nameCn=${nameCn}&abbv=${abbv}`
  );
  const result = await res.json();
  console.log("==== checkCourtNameDuplication", result);
  return result;
};

export const checkRouteNameDuplication = async (name) => {
  const res = await fetch(`/api/checkNameDuplication?type=route&name=${name}`);
  const result = await res.json();
  return result >= 2;
};

export const loopLogs = (tempLogsOfTrip, trip, accuDistance) => {
  let prevPoint = null;

  let sameCoordCount = 0;
  let smoothArr = [];

  const speedRelated = {
    accuKm: 0,
    prevTime: null,
  };

  let logSliceIndex = null;

  for (let index = 0; index < tempLogsOfTrip.length; index++) {
    const tempLog = tempLogsOfTrip[index];

    if (moment(tempLog.time).isBefore(moment(trip.startTime))) {
      logSliceIndex = { ...logSliceIndex, from: index };
      continue;
    }
    if (moment(tempLog.time).isAfter(moment(trip.endTime))) {
      logSliceIndex = { ...logSliceIndex, to: index };
      break;
    }

    if (prevPoint === null) {
      tempLog.distance = accuDistance;
      tempLog.realSpeed = 0;
      speedRelated.prevTime = moment(tempLog.time);
    } else {
      const newDistance = distance(
        prevPoint,
        point([
          tempLog.coordinates.coordinates[0],
          tempLog.coordinates.coordinates[1],
        ])
      );

      if (newDistance == 0) {
        sameCoordCount += 1;
        smoothArr.push(index);
      } else {
        if (sameCoordCount >= 10) {
          const distanceSpread = newDistance / sameCoordCount;
          let prevSmoothDistance = 0;
          smoothArr.forEach((sIndex, i) => {
            if (i == 0) {
              tempLogsOfTrip[sIndex].distance =
                tempLogsOfTrip[sIndex].distance + distanceSpread;
            } else {
              tempLogsOfTrip[sIndex].distance =
                prevSmoothDistance + distanceSpread;
            }
            prevSmoothDistance = tempLogsOfTrip[sIndex].distance;
          });
        }
        sameCoordCount = 0;
        smoothArr = [];
      }
      accuDistance += newDistance;
      tempLog.distance = accuDistance;

      speedRelated.accuKm += newDistance;

      const isTimeNotClose =
        moment
          .duration(moment(tempLog.time).diff(speedRelated.prevTime))
          .asSeconds() > 3;

      if (speedRelated.accuKm >= 0.1 && isTimeNotClose) {
        tempLog.realSpeed =
          speedRelated.accuKm /
          moment
            .duration(moment(tempLog.time).diff(speedRelated.prevTime))
            .asHours();
        speedRelated.prevTime = moment(tempLog.time);
        speedRelated.accuKm = 0;
      }
    }
    prevPoint = point([
      tempLog.coordinates.coordinates[0],
      tempLog.coordinates.coordinates[1],
    ]);
  }

  if (logSliceIndex) {
    return {
      accuDistance,
      log: tempLogsOfTrip.slice(logSliceIndex.from, logSliceIndex.to),
    };
  }
  return { accuDistance, log: tempLogsOfTrip };
};

export const loopLogsWithoutTrips = (tempLogs) => {
  let prevPoint = null;
  let prevTime = null;

  const trips = [];
  let sameCoordCount = 0;
  let smoothArr = [];

  let accuDistance = 0;
  let accuTime = 0;

  const speedRelated = {
    accuKm: 0,
    prevTime: null,
  };

  for (let index = 0; index < tempLogs.length; index++) {
    const tempLog = tempLogs[index];

    // check is log belong to same trip
    // if not init all data
    if (index > 0) {
      if (tempLogs[index - 1].tripId !== tempLog.tripId) {
        prevPoint = null;
        prevTime = null;
        // add trip to array if not exist
        if (!trips.includes(tempLog.tripId)) {
          trips.push(tempLog.tripId);
        }
      }
    }

    if (prevPoint === null) {
      // init first point
      tempLog.distance = accuDistance;
      tempLog.realSpeed = 0;
      speedRelated.prevTime = moment(tempLog.time);
    } else {
      // get distance between current point and previous point
      const newDistance = distance(
        prevPoint,
        point([
          tempLog.coordinates.coordinates[0],
          tempLog.coordinates.coordinates[1],
        ])
      );

      accuTime += moment
        .duration(moment(tempLog.time).diff(moment(prevTime)))
        .asMinutes();

      if (newDistance == 0) {
        sameCoordCount += 1;
        smoothArr.push(index);
      } else {
        if (sameCoordCount >= 10) {
          const distanceSpread = newDistance / sameCoordCount;
          let prevSmoothDistance = 0;
          smoothArr.forEach((sIndex, i) => {
            if (i == 0) {
              tempLogs[sIndex].distance =
                tempLogs[sIndex].distance + distanceSpread;
            } else {
              tempLogs[sIndex].distance = prevSmoothDistance + distanceSpread;
            }
            prevSmoothDistance = tempLogs[sIndex].distance;
          });
        }
        sameCoordCount = 0;
        smoothArr = [];
      }
      accuDistance += newDistance;
      tempLog.distance = accuDistance;

      speedRelated.accuKm += newDistance;
      const isTimeNotClose =
        moment
          .duration(moment(tempLog.time).diff(speedRelated.prevTime))
          .asSeconds() > 3;

      if (speedRelated.accuKm >= 0.1 && isTimeNotClose) {
        tempLog.realSpeed =
          speedRelated.accuKm /
          moment
            .duration(moment(tempLog.time).diff(speedRelated.prevTime))
            .asHours();

        speedRelated.prevTime = moment(tempLog.time);
        speedRelated.accuKm = 0;
      }
    }
    prevPoint = point([
      tempLog.coordinates.coordinates[0],
      tempLog.coordinates.coordinates[1],
    ]);
    prevTime = tempLog.time;
  }

  return { accuDistance, log: tempLogs, trips, accuTime };
};

export const getVehiclesPreviousLogs = async (vehicleIds) => {
  let str = ``;
  vehicleIds.forEach((vehicleId, index) => {
    str += `${index > 0 ? "&" : ""}vehicleId=${vehicleId}`;
  });
  const response = await fetch(`/api/previousLogs?${str}`);
  if (response) {
    const result = await response.json();
    return result;
  }
  return [];
};

export const getFences = async (filter) => {
  const response = await fetch(`/api/geoFencing${constructParams(filter)}`);
  if (response) {
    const result = await response.json();
    return result;
  }
  return [];
};

export const updateVehicleOrdering = (index, ordering) => {
  let tempOrdering = [...ordering];
  if (tempOrdering[index].order === 0) {
    tempOrdering[index].order = 1;
  } else if (tempOrdering[index].order === 1) {
    tempOrdering[index].order = 2;
  } else {
    tempOrdering[index].order = 0;
  }
  tempOrdering = tempOrdering.map((order, i) => {
    if (i !== index) {
      return {
        ...order,
        order: 0,
      };
    }
    return order;
  });
  return tempOrdering;
};

export const sortedVehicles = (vehicles, ordering) => {
  const orderingFields = [];
  const orderingOrders = [];
  ordering
    .filter(({ order }) => order > 0)
    .forEach(({ field, order }) => {
      orderingFields.push(field);
      orderingOrders.push(order === 1 ? "asc" : "desc");
    });
  const sortedVehicles = orderBy(vehicles, orderingFields, orderingOrders);
  return sortedVehicles;
};

export const updateLicense = async (plate, license) => {
  const response = await fetch(`/api/updateLicense`, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: JSON.stringify({
      plate,
      license,
    }),
  });
  if (response) {
    const result = await response.json();
    return result;
  }
  return [];
};

export const updatePublicMessage = async (
  settingId,
  message,
  messageEn,
  messageCn,
  expiry
) => {
  const response = await fetch(`${OMP_360_API_URL}/public-message`, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: JSON.stringify({
      settingId,
      message,
      messageEn,
      messageCn,
      expiry,
    }),
  });
  if (response) {
    const result = await response.json();
    return result;
  }
  return [];
};

export const fetchPublicMessage = async (settingId) => {
  const rawResponse = await fetch(
    `${OMP_360_API_URL}/public-message/${settingId}`
  );
  const response = await rawResponse.json();
  return response;
};

export const fetchSchedulesOfCourt = async (courtId) => {
  const rawResponse = await fetch(
    `/api/getCourtSchedules?courtId=${courtId}&all=true&getLatestDailySchedule=true`
  );
  const response = await rawResponse.json();
  return response;
};

export const fetchLatestScheduleUpdateRecord = async (courtId) => {
  const rawResponse = await fetch(
    `/api/scheduleUpdateRecord?courtId=${courtId}`
  );
  const response = await rawResponse.json();
  return response;
};

export const fetchDisabledScheduleSlot = async (filters) => {
  const rawResponse = await fetch(
    `/api/disabledScheduleSlot${constructParams(filters)}`
  );
  const response = await rawResponse.json();
  return response;
};

export const fetchSettings = async (filters) => {
  const rawResponse = await fetch(
    `/api/getSettings${constructParams(filters)}`
  );
  const response = await rawResponse.json();
  return response;
};

export const listAllOperatorPsl = (operators) => {
  const result = [];
  if (Array.isArray(operators)) {
    operators.forEach((operator) => {
      operator.psl.forEach((psl) => {
        result.push({
          ...operator,
          psl,
        });
      });
    });
  } else {
    operators.psl.forEach((psl) => {
      result.push({
        ...operators,
        psl,
      });
    });
  }
  return result;
};

export const durationParse = (millisecond) => {
  const duration = moment.duration(Math.abs(millisecond));
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  let output = "";

  if (millisecond === 0) {
    output = `0${i18n.t("秒")}`;
  } else {
    if (millisecond < 0) {
      output += "-";
    }
    if (hours !== 0) {
      output += `${hours}${i18n.t("小時")}`;
    }
    if (minutes !== 0) {
      output += `${minutes / 10 < 1 ? `0` : ""}${minutes}${i18n.t("分")}`;
    }
    // console.log(seconds);
    if (seconds !== 0) {
      output += `${seconds / 10 < 1 ? `0` : ""}${seconds}${i18n.t("秒")}`;
    }
    // console.log(output);
    // else if (minutes !== 0) {
    //   output += `${i18n.t("鐘")}`;
    // }
  }
  return output;
};
